import React from 'react'
import { Box } from '@chakra-ui/react'

export default function SimpleLayout (props) {
  return (
    <Box
      bgColor={'bg.100'}
      minH={'100vh'}
      px={'custom'}
      pb={'custom'}
      {...props}
    />
  )
}
