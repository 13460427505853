// import hexToRgba from '~/utils/hexToRgba'

export const outline = ({ colorScheme, theme }) => ({
  bg: 'white',
  borderColor: 'custom.border',
  borderWidth: 1,
  color: 'palette.darkGreen',
  _hover: {
    // borderColor: hexToRgba(theme.colors.palette.gray, 0.5)
  },
  _focus: {
    bg: 'white',
    borderColor: `${colorScheme}.500`
  }
})

export const filled = ({ colorScheme, theme }) => ({
  // bg: hexToRgba(theme.colors.palette.lightGray, '0.5'),
  _hover: {
    // bg: hexToRgba(theme.colors.palette.lightGray, '0.75')
  },
  _focus: {
    bg: 'white',
    borderColor: `${colorScheme}.500`
  }
})
