import React from 'react'
import { Stack } from '@chakra-ui/react'

import LoginInput from './LoginInput'

export default function LoginGCPField () {
  return (
    <Stack>
      <LoginInput
        name={'gcp'}
        label={'Введите номер GCP'}
        maxLength={9}
        placeholder={'000 000 000'}
        rules={{
          required: 'Это поле обязательно',
          minLength: {
            value: 9,
            message: 'Убедитесь, что в этом поле как минимум 9 символов'
          }
        }}
      />
    </Stack>
  )
}
