import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Container,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react'

import Logo from '~/components/Logo'
import LoginHeading from '~/routes/Login/components/LoginHeading'
import Footer from '~/components/Footer'
import Navigation from '~/components/Navigation'

function AuthLayout (props) {
  const { children } = props

  return (
    <Flex
      bgColor={'#f4f4f4'}
      direction={'column'}
      justify={'space-between'}
      minH={'100vh'}>
      <Flex
        align={'center'}
        bgColor={'bg.200'}
        boxShadow={'md'}
        justify={'space-between'}>
        <Container
          alignItems={'center'}
          display={'flex'}
          h={24}
          maxW={'container.lg'}
          w={'full'}>
          <Logo />
        </Container>
      </Flex>

      <Navigation />

      <Container py={'custom'} maxW={'container.lg'}>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 12, lg: 20 }}>
          {/** Login form */}
          {children}

          {/** Register */}
          <Stack align={'start'} spacing={5}>
            <LoginHeading as={'h1'}>
              Присоединяйтесь к <br /> GS1 Uzbekistan
            </LoginHeading>

            <Stack spacing={4}>
              <Text>
                Только вступив в GS1 Uzbekistan, Вы сможете получать международные идентификаторы (штриховые коды),
                которые гарантированно будут приниматься Вашими торговыми партнерами.
              </Text>
              <Text>
                Вы сможете получить доступ к разнообразным сервисам, обучению и поддержке, которые помогут Вам в
                развитии Вашего бизнеса.
              </Text>

              <Text>
                Присоединяйтесь сейчас, получите штриховой-код на свою продукцию и реализуйте ее в любом месте по всему
                миру.
              </Text>
            </Stack>

            <Button
              as={Link}
              colorScheme={'secondary'}
              isExternal={true}
              href={'http://member.478.uz/'}
              size={'md'}
              _hover={{ textDecor: 'none' }}>
              Зарегистрироваться
            </Button>
          </Stack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Flex>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthLayout
