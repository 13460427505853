import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import { getDocumentLink } from '~/utils/url'

export function printContract (contractId) {
  getDocumentLink(sprintf(API.CONTRACT_PRINT, contractId), { stamp: 1 })
}

export function printInvoice (invoiceId) {
  getDocumentLink(sprintf(API.INVOICE_PRINT, invoiceId), { stamp: 1 })
}

export function printInvoiceCertificate (invoiceId) {
  getDocumentLink(sprintf(API.INVOICE_CERTIFICATE_PRINT, invoiceId), { show_stamp: true })
}
