/* eslint-disable max-len */
import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function Logo (props) {
  const orange = '#f26334'
  const blue = '#002c6c'

  return (
    <Icon width={'120px'} height={'58px'} viewBox={'0 0 60 29'} fill={'none'} {...props}>
      <path d={'M28.9535 25.8582V23.5591H29.8279V25.8344C29.8279 26.4895 30.1505 26.8285 30.6831 26.8285C31.2157 26.8285 31.5392 26.5014 31.5392 25.8634V23.5591H32.4112V25.8292C32.4112 27.0472 31.7375 27.6443 30.6713 27.6443C29.6061 27.6443 28.9535 27.041 28.9535 25.8582Z'} fill={orange} />
      <path d={'M33.0516 26.9671L34.6218 25.2085H33.1037V24.5021H35.6922V25.1168L34.1164 26.8753H35.6922V27.5818H33.0516V26.9671Z'} fill={orange} />
      <path d={'M37.148 27.1804V27.5816H36.2863V23.3866H37.148V24.9436C37.3576 24.6564 37.6468 24.4439 38.0955 24.4439C38.8025 24.4439 39.4772 25.0077 39.4772 26.0356V26.0475C39.4772 27.0772 38.8143 27.6391 38.0955 27.6391C37.6361 27.6391 37.3524 27.4266 37.148 27.1804ZM38.615 26.0475V26.0356C38.615 25.5245 38.276 25.1855 37.8742 25.1855C37.4715 25.1855 37.1372 25.5245 37.1372 26.0356V26.0475C37.1372 26.559 37.4715 26.898 37.8742 26.898C38.2765 26.898 38.615 26.5647 38.615 26.0475Z'} fill={orange} />
      <path d={'M39.9071 26.0591V26.0476C39.9071 25.1681 40.525 24.4441 41.4088 24.4441C42.4234 24.4441 42.8885 25.2433 42.8885 26.1171C42.8885 26.1855 42.8829 26.2659 42.8777 26.3462H40.7632C40.849 26.7432 41.1209 26.9505 41.5054 26.9505C41.7942 26.9505 42.0047 26.8577 42.2415 26.6338L42.7352 27.0779C42.452 27.4325 42.0441 27.6512 41.4941 27.6512C40.5822 27.6507 39.9071 27.0013 39.9071 26.0591ZM42.0497 25.8009C41.9986 25.4096 41.7722 25.1453 41.4088 25.1453C41.0525 25.1453 40.8199 25.4044 40.752 25.8009H42.0497Z'} fill={orange} />
      <path d={'M43.4718 23.3861H44.334V25.622L45.342 24.5014H46.3735L45.2164 25.7142L46.4133 27.5816H45.4152L44.6402 26.317L44.334 26.6455V27.5816H43.4718V23.3861Z'} fill={orange} />
      <path d={'M46.787 23.3861H47.6947V24.1625H46.787V23.3861ZM46.809 24.5014H47.6717V27.5816H46.809V24.5014Z'} fill={orange} />
      <path d={'M48.1851 27.1737L48.554 26.599C48.8827 26.8405 49.2282 26.9665 49.5119 26.9665C49.7608 26.9665 49.8743 26.8747 49.8743 26.7373V26.7254C49.8743 26.5353 49.5794 26.473 49.2461 26.3689C48.8213 26.2424 48.3384 26.0413 48.3384 25.4447V25.4333C48.3384 24.8067 48.8363 24.4563 49.4486 24.4563C49.834 24.4563 50.2536 24.5875 50.5823 24.8119L50.2536 25.4162C49.9535 25.2374 49.6535 25.1285 49.4317 25.1285C49.2226 25.1285 49.1143 25.2203 49.1143 25.3415V25.352C49.1143 25.5245 49.4041 25.6054 49.7318 25.7204C50.1575 25.8635 50.6502 26.0703 50.6502 26.6337V26.6456C50.6502 27.3292 50.1462 27.6392 49.4889 27.6392C49.0641 27.6392 48.5892 27.4951 48.1851 27.1737Z'} fill={orange} />
      <path d={'M51.2967 26.7075V25.2485H50.9343V24.5016H51.2967V23.7144H52.1594V24.5016H52.8725V25.2485H52.1594V26.5649C52.1594 26.7655 52.2433 26.8639 52.437 26.8639C52.5954 26.8639 52.7375 26.8231 52.8608 26.7546V27.4568C52.6803 27.5657 52.4698 27.6351 52.1815 27.6351C51.654 27.6341 51.2967 27.4207 51.2967 26.7075Z'} fill={orange} />
      <path d={'M53.2692 26.6961V26.6857C53.2692 26.0125 53.7732 25.702 54.4929 25.702C54.7985 25.702 55.0208 25.7539 55.236 25.8295V25.7772C55.236 25.4159 55.0142 25.2143 54.5833 25.2143C54.2551 25.2143 54.0231 25.2775 53.7446 25.3807L53.5289 24.7137C53.8641 24.5653 54.1923 24.4674 54.7085 24.4674C55.1792 24.4674 55.5192 24.5934 55.7344 24.8121C55.9613 25.0417 56.063 25.3807 56.063 25.7952V27.5818H55.2299V27.2486C55.0212 27.4839 54.732 27.6393 54.3123 27.6393C53.7394 27.6389 53.2692 27.3056 53.2692 26.6961ZM55.2468 26.4955V26.3405C55.1 26.2711 54.9073 26.2255 54.6973 26.2255C54.3292 26.2255 54.1018 26.3738 54.1018 26.65V26.6619C54.1018 26.8977 54.2945 27.0351 54.573 27.0351C54.9758 27.0351 55.2468 26.8112 55.2468 26.4955Z'} fill={orange} />
      <path d={'M56.748 24.5016H57.6098V24.9385C57.8086 24.6799 58.0632 24.4441 58.4997 24.4441C59.1518 24.4441 59.5306 24.881 59.5306 25.587V27.5813H58.6689V25.8632C58.6689 25.4496 58.4767 25.2371 58.148 25.2371C57.8189 25.2371 57.6098 25.4496 57.6098 25.8632V27.5813H56.748V24.5016Z'} fill={orange} />
      <path d={'M33.6349 17.8552V16.9367H31.9593V9.95483H31.1444L28.9244 10.8733L29.1509 11.8836L30.872 11.1947V16.9362H28.969V17.8547H33.6349V17.8552Z'} fill={blue} />
      <path d={'M27.9277 14.0884C27.9277 21.5751 21.903 27.6375 14.4751 27.6375C7.04627 27.6375 1.02161 21.5751 1.02161 14.0884C1.06662 6.60161 7.04674 0.539185 14.4751 0.539185C21.9035 0.539185 27.9277 6.60161 27.9277 14.0884Z'} fill={blue} />
      <path d={'M17.2381 10.0011C15.2455 10.0011 13.7499 11.6546 13.7499 13.9052V13.9969C13.7499 16.3388 15.155 17.901 17.1926 17.901C18.3249 17.901 19.1857 17.4413 19.7291 17.0272V13.6751H17.1471V14.5018H18.8228V16.4767L18.7778 16.5228C18.3699 16.798 17.827 16.9815 17.2831 16.9815C15.7879 16.9815 14.8367 15.7878 14.8367 13.9508V13.9052C14.8367 12.2056 15.8334 10.9196 17.2376 10.9196C18.0529 10.9196 18.5513 11.1949 18.9587 11.5623L19.5481 10.7822C18.8228 10.2307 18.1439 10.0011 17.2381 10.0011Z'} fill={'white'} />
      <path d={'M21.1787 16.845C21.9486 17.5329 22.8544 17.8552 23.8056 17.8552C25.2553 17.8552 26.2061 16.9819 26.2061 15.6959C26.2061 14.64 25.6632 14.0429 24.1685 13.4919C22.8098 12.9404 22.4474 12.6195 22.4474 11.9763C22.4474 11.3331 22.9913 10.8738 23.7611 10.8738C24.305 10.8738 24.8924 11.1034 25.4813 11.5627L26.0711 10.7825C25.4368 10.2311 24.6664 9.95532 23.8056 9.95532C22.4015 9.95532 21.4506 10.8277 21.4506 12.0219C21.4506 13.2623 22.2205 13.7676 23.6256 14.3182C24.8474 14.7779 25.2558 15.0997 25.2558 15.7434C25.2558 16.4318 24.6664 16.9372 23.8521 16.9372C22.9458 16.9372 22.2669 16.4775 21.8135 16.0643L21.1787 16.845Z'} fill={'white'} />
      <path d={'M24.7578 2.88203C24.7578 2.14752 25.3453 1.54993 26.071 1.54993C26.7959 1.54993 27.3843 2.14752 27.3843 2.88203C27.3843 3.61653 26.7959 4.21365 26.071 4.21365C25.3453 4.21365 24.7578 3.61653 24.7578 2.88203ZM27.1124 2.88203C27.1124 2.28491 26.659 1.82519 26.0706 1.82519C25.4808 1.82519 25.0283 2.28443 25.0283 2.88203C25.0283 3.47866 25.4808 3.93839 26.0706 3.93839C26.659 3.93839 27.1124 3.47866 27.1124 2.88203ZM25.5717 2.23832H26.1611C26.4325 2.23832 26.659 2.37619 26.659 2.65193C26.659 2.83591 26.569 2.9733 26.3875 3.06553L26.704 3.52478H26.3425L26.071 3.11165H25.8896V3.52478H25.5717V2.23832ZM26.1611 2.83591C26.2975 2.83591 26.3875 2.74416 26.3875 2.65193C26.3875 2.5597 26.2975 2.46794 26.1611 2.46794H25.8896V2.83544H26.1611V2.83591Z'} fill={blue} />
      <path d={'M3.7845 14.0884C3.7845 7.88808 8.49546 2.8359 14.5206 2.33054V2.28442C8.04256 2.28442 2.78821 7.56623 2.78821 14.0884C2.78821 20.611 8.04256 25.8928 14.4751 25.8928V25.8472C8.49546 25.3413 3.7845 20.243 3.7845 14.0884Z'} fill={'white'} />
      <path d={'M6.41191 14.0887C6.41191 8.89861 10.3075 4.62753 15.335 4.12216L15.29 4.07605C9.89965 4.1678 5.5061 8.62334 5.5061 14.0887C5.5061 19.5549 9.85417 24.0105 15.29 24.1017L15.335 24.0561C10.3071 23.5041 6.41191 19.2792 6.41191 14.0887Z'} fill={'white'} />
      <path d={'M8.90339 14.0882C8.90339 9.86322 12.0742 6.37229 16.1508 5.8674L16.1053 5.82129C11.6658 5.95916 8.13354 9.58749 8.13354 14.0882C8.13354 18.5898 11.6658 22.2187 16.1053 22.356L16.1508 22.3095C12.0737 21.8046 8.90339 18.3141 8.90339 14.0882Z'} fill={'white'} />
      <path d={'M11.3043 14.0884C11.3043 10.7824 13.7498 8.0264 16.9661 7.65891V7.61279C13.4784 7.70455 10.7155 10.5527 10.7155 14.0888C10.7155 17.6254 13.5234 20.4736 16.9661 20.5658V20.5187C13.7958 20.1056 11.3043 17.3963 11.3043 14.0884Z'} fill={'white'} />
    </Icon>
  )
}
