import React from 'react'
import { css, Global } from '@emotion/react'

export default function Fonts () {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'SFProDisplay';
          font-style: normal;
          font-weight: 400;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-Regular.woff') format('woff');
        }
        @font-face {
          font-family: SFProDisplay;
          font-style: italic;
          font-weight: 400;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-RegularItalic.woff') format('woff');
        }

        @font-face {
          font-family: SFProDisplay;
          font-style: normal;
          font-weight: 500;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-Medium.woff') format('woff');
        }
        @font-face {
          font-family: SFProDisplay;
          font-style: italic;
          font-weight: 500;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-MediumItalic.woff') format('woff');
        }

        @font-face {
          font-family: SFProDisplay;
          font-style: normal;
          font-weight: 600;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-Semibold.woff') format('woff');
        }
        @font-face {
          font-family: SFProDisplay;
          font-style: italic;
          font-weight: 600;
          font-display: fallback;
          src: url('/fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
        }
      `}
    />
  )
}
