import React from 'react'
import { Search } from 'react-iconly'
import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'

import { useAddParamsURL, useGetSearchParam } from '~/hooks/urls'

export default function TableSearch (props) {
  const addParamsURL = useAddParamsURL()

  const getSearchParam = useGetSearchParam()

  const searchValue = getSearchParam('search')

  function onKeyPress (event) {
    if (event.code === 'Enter') {
      const value = event.target.value

      addParamsURL({
        page: 1,
        search: value
      })
    }
  }

  return (
    <InputGroup>
      <InputLeftElement height={10} pointerEvents={'none'}>
        <Icon as={Search} boxSize={'18px'} color={'text.100'} />
      </InputLeftElement>

      <Input
        defaultValue={searchValue}
        name={'search'}
        variant={'unstyled'}
        height={10}
        onKeyPress={onKeyPress}
        {...props}
      />
    </InputGroup>
  )
}
