import {
  ProductListContainer,
  ProductCreateContainer,
  ProductEditContainer
} from './containers'

import * as ROUTES from '~/constants/routes'
import { ListLayout, SimpleLayout } from '~/components/Layouts'

export default [
  {
    path: ROUTES.PRODUCT_LIST_PATH,
    layout: ListLayout,
    component: ProductListContainer,
    routes: [
      {
        path: ROUTES.PRODUCT_CREATE_PATH,
        layout: SimpleLayout,
        component: ProductCreateContainer
      },
      {
        path: ROUTES.PRODUCT_EDIT_PATH,
        layout: SimpleLayout,
        component: ProductEditContainer
      }
    ]
  }
]
