import { prop } from 'ramda'

// import { useTranslate } from '~/utils/translate'

export default function useFieldError (error) {
  // const { t } = useTranslate()

  const errorMessages = {
    required: 'Обязательное поле'
  }

  const errorType = prop('type', error)

  return prop('message', error) || prop(errorType, errorMessages)
}
