import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { useFormContext, useFormState } from 'react-hook-form'
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'

function LoginInput (props) {
  const { name, label, rules, ...restProps } = props

  const { register } = useFormContext()
  const { errors } = useFormState()

  const field = register(name, rules)

  const error = prop(name, errors)
  const errorMessage = prop('message', error)

  return (
    <FormControl id={name} isInvalid={!!error}>
      <FormLabel mb={3}>{label}</FormLabel>
      <Input{...field} {...restProps} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

LoginInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object
}

export default LoginInput
