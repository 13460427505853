import { outline, filled } from './common/inputVariants'

export default {
  defaultProps: {
    colorScheme: 'primary',
    errorBorderColor: 'custom.red',
    focusBorderColor: 'primary.500',
    variant: 'outline'
  },
  baseStyle: {
    display: 'block'
  },
  sizes: {
    md: {
      borderRadius: 'sm',
      fontWeight: 'inherit',
      minH: 28,
      px: 3,
      py: 4
    }
  },
  variants: {
    filled,
    outline
  }
}
