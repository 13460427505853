import React, { forwardRef } from 'react'
import { Circle, IconButton, Stack } from '@chakra-ui/react'

function Dot (props) {
  return (
    <Circle bgColor={'currentColor'} size={'3px'} {...props} />
  )
}

function Dots (props) {
  return (
    <Stack direction={'row'} spacing={'3px'}>
      <Dot />
      <Dot />
      <Dot />
    </Stack>
  )
}

export default forwardRef(function MoreButton (props, ref) {
  return (
    <IconButton
      ref={ref}
      bg={'#eaebef'}
      borderRadius={'base'}
      color={'text.200'}
      icon={<Dots />}
      height={5}
      minW={'unset'}
      width={8}
      _hover={{ color: 'primary.900' }}
      _active={{ bg: '#dfe1e6', color: 'primary.900' }}
      {...props}
    />
  )
})
