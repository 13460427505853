/* eslint-disable max-len */
import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function CloudUpload (props) {
  return (
    <Icon boxSize={6} viewBox={'0 0 34 34'} fill={'none'} {...props}>
      <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M11.9857 10.3189C13.3211 8.25548 15.644 6.88757 18.2881 6.88757C22.2741 6.88757 25.5342 9.99645 25.7756 13.9218C25.8552 13.9187 25.9351 13.9172 26.0153 13.9172C29.4473 13.9172 32.2293 16.6993 32.2293 20.1312C32.2293 23.5631 29.4473 26.3453 26.0153 26.3453H9.91683C5.41801 26.3453 1.771 22.6982 1.771 18.1994C1.771 13.7006 5.41801 10.0536 9.91683 10.0536C10.6307 10.0536 11.3243 10.1457 11.9857 10.3189ZM21.3714 17.6638C21.0049 18.1221 20.3363 18.1963 19.878 17.8298L18.0627 16.3775V21.2501C18.0627 21.8369 17.5869 22.3126 17.0002 22.3126C16.4134 22.3126 15.9377 21.8369 15.9377 21.2501V16.3773L14.1222 17.8298C13.664 18.1963 12.9953 18.1221 12.6288 17.6638C12.2622 17.2056 12.3365 16.537 12.7947 16.1703L16.3326 13.3401C16.5128 13.1943 16.7418 13.1063 16.9912 13.1043L17.0002 13.1042H17.0036C17.2573 13.1051 17.4902 13.1948 17.6724 13.3439L21.2055 16.1703C21.6637 16.537 21.7381 17.2056 21.3714 17.6638Z'} fill={'currentColor'} />
    </Icon>
  )
}
