import React from 'react'
import PropTypes from 'prop-types'
import { defaultTo, is, isEmpty, join, map, not, path, pipe, prop } from 'ramda'
import { Box, Image, SimpleGrid, Stack } from '@chakra-ui/react'

import ProductStatus from '../ProductStatus'

import ProductBarcode from './ProductBarcode'

import * as CONST from '~/constants/constants'
import { numberFormat } from '~/utils/number'
import { DetailValue } from '~/components/DetailDrawer'

function ValueStack (props) {
  return (
    <Stack spacing={0} textAlign={'right'} {...props} />
  )
}

function ProductDetail (props) {
  const { detail } = props

  const { data, isLoading } = detail

  const myEancode = prop('myEancode', data)
  const tradeMark = prop('tradeMark', data)
  const name = prop('name', data)
  const description = prop('description', data)
  const packageName = prop('package', data)
  const quantity = prop('quantity', data)
  const measurement = prop('measurement', data)
  const measurementName = CONST.MEASUREMENT.object[measurement]
  const quantityMeasurement = numberFormat(quantity, measurementName)
  const targetMarket = prop('targetMarket', data)
  const targetMarkets = is(Array, targetMarket)
    ? pipe(
      map(prop('name')),
      join(', '),
      defaultTo('-')
    )(targetMarket)
    : '-'
  const gpcCode = path(['productType', 'code'], data)
  const status = prop('status', data)
  const files = prop('files', data)
  const packages = prop('packages', data) || []

  if (isLoading) {
    return (
      <div>Loading</div>
    )
  }

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <DetailValue label={'Торговая марка'}>
          {tradeMark}
        </DetailValue>
        <DetailValue label={'Вторичная торговая марка'}>
          {name}
        </DetailValue>
        <DetailValue label={'Описание'}>
          {description}
        </DetailValue>
        <DetailValue label={'Упаковка и кол-во'}>
          <ValueStack>
            <Box>{packageName}</Box>
            <Box>{quantityMeasurement}</Box>
          </ValueStack>
        </DetailValue>
        <DetailValue label={'Целевые рынки'}>
          {targetMarkets}
        </DetailValue>
        <DetailValue label={'Номер GPC'}>
          {gpcCode}
        </DetailValue>
        <DetailValue label={'Статус'}>
          <ProductStatus status={status} />
        </DetailValue>
      </Stack>

      {myEancode && (
        <DetailValue label={'Штрих код'} variant={'block'}>
          <ProductBarcode barcode={myEancode} />
        </DetailValue>
      )}

      {not(isEmpty(packages)) && (
        <DetailValue label={'Упаковки'} variant={'block'}>
          <Stack spacing={4}>
            {packages.map(pack => {
              const packId = prop('id', pack)
              const packCode = prop('eancode', pack)
              const packName = prop('package', pack)
              const packQuantity = prop('quantity', pack)
              const packMeasurement = CONST.MEASUREMENT.object[prop('measurement', pack)]
              const packQuantityMeasurement = numberFormat(packQuantity, packMeasurement)

              return (
                <Box key={packId} fontSize={'sm'}>
                  <Box>{packName} {packQuantityMeasurement}</Box>
                  <Box>{packCode}</Box>
                </Box>
              )
            })}
          </Stack>
        </DetailValue>
      )}

      {not(isEmpty(files)) && (
        <DetailValue label={'Фото упаковки'} variant={'block'}>
          <SimpleGrid columns={3} spacing={2}>
            {files.map(file => {
              const fileId = prop('id', file)
              const filePath = prop('file', file)

              return (
                <Image
                  key={fileId}
                  src={filePath}
                  objectFit={'cover'}
                  objectPosition={'center'}
                  h={32}
                  w={'full'}
                />
              )
            })}
          </SimpleGrid>
        </DetailValue>
      )}
    </Stack>
  )
}

ProductDetail.propTypes = {
  detail: PropTypes.object
}

export default ProductDetail
