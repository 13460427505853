import { map, path, pipe, prop, propOr } from 'ramda'

export function productCreateSerializer (values) {
  return {
    eancodeType: 'ean-13',
    productType: path(['productType', 'code'], values),
    name: prop('name', values),
    description: prop('description', values),
    tradeMark: prop('tradeMark', values),
    package: prop('package', values),
    quantity: prop('quantity', values),
    measurement: path(['measurement', 'id'], values),
    files: pipe(
      propOr([], 'files'),
      map(prop('id'))
    )(values),
    targetMarket: pipe(
      propOr([], 'targetMarket'),
      map(prop('id'))
    )(values),
    packages: pipe(
      propOr([], 'packages'),
      map(pack => ({
        eancodeType: 'itf-14',
        package: prop('package', pack),
        quantity: prop('quantity', pack),
        measurement: path(['measurement', 'id'], pack)
      }))
    )(values)
  }
}
