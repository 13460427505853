import { arrayOfObjToObj } from '~/utils/array'

export const PRODUCT_STATUSES = {
  APPROVED: 'approved',
  WAITING_APPROVE: 'waiting_approve',
  REJECTED: 'rejected'
}

const splitList = list => ({
  list,
  object: arrayOfObjToObj(list)
})

export const MEASUREMENT = splitList([
  { id: 'gram', name: 'гр' },
  { id: 'kilogram', name: 'кг' },
  { id: 'milligram', name: 'мг' },
  { id: 'liter', name: 'л' },
  { id: 'milliliter', name: 'мл' },
  { id: 'pieces', name: 'шт' },
  { id: 'set', name: 'набор' },
  { id: 'tone', name: 'тонна' },
  { id: 'couple', name: 'пара' },
  { id: 'kit', name: 'комплект' },
  { id: 'miu', name: 'ед' }
])
