import React from 'react'
import PropTypes from 'prop-types'
import { useBarcode } from 'react-barcodes'

function Barcode (props) {
  const { render: Render, value, onLoad, ...restProps } = props

  const { inputRef } = useBarcode({
    value,
    options: {
      format: 'EAN13',
      ...restProps
    }
  })

  return (
    <Render ref={inputRef} onLoad={onLoad} />
  )
}

Barcode.propTypes = {
  value: PropTypes.string.isRequired,
  render: PropTypes.string,
  onLoad: PropTypes.func
}

Barcode.defaultProps = {
  render: 'svg'
}

export default Barcode
