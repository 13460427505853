export default {
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg'
  },
  baseStyle: {
    fontWeight: 'medium',
    _focus: { boxShadow: 'none' }
  },
  sizes: {
    lg: {
      borderRadius: 'sm',
      fontSize: 'md',
      h: '52px'
    }
  }
}
