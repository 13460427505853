import { path, has, prop, omit } from 'ramda'

import useGetApi from '../api/useGetApi'
import useDeepCompareEffect from '../app/useDeepCompareEffect'

export function toList (state) {
  const data = prop('data', state)
  const results = has('results', data)
    ? path(['data', 'results'], state)
    : data || []
  const count = path(['data', 'count'], state) || results.length
  const isLoading = state.isLoading
  const getList = state.getList

  return { count, results, isLoading, getList }
}

export default function useList (api, { params, autoSend = true } = {}) {
  const filteredParams = omit(['select'], params)

  const { get, ...state } = useGetApi(api)
  const getList = get

  useDeepCompareEffect(() => {
    if (autoSend) {
      getList(filteredParams)
    }
  }, [getList, filteredParams])

  return toList({ ...state, getList })
}
