export default {
  baseStyle: {
    list: {
      borderRadius: 'base',
      // borderWidth: 0,
      p: '5px'
    },
    item: {
      borderRadius: 'base',
      color: 'primary.900',
      fontSize: 'sm',
      px: '5px',
      py: '10px',
      transition: 'all 100ms ease-in',
      '& > .chakra-menu__icon-wrapper': {
        mr: 2
      },
      _hover: {
        color: 'custom.blue'
      }
    },
    divider: {
      borderColor: 'custom.border',
      mx: '5px',
      my: '5px',
      opacity: 1
    }
  }
}
