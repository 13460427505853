const ID = ':id(\\d+)'
const CREATE = 'create'
const EDIT = 'edit'

export const ROOT_PATH = '/'

const LOGIN = 'login'
export const LOGIN_PATH = `/${LOGIN}/`

// Products
const PRODUCTS = 'products'
export const PRODUCT_LIST_PATH = `/${PRODUCTS}/`
export const PRODUCT_CREATE_PATH = `/${PRODUCTS}/${CREATE}/`
export const PRODUCT_EDIT_PATH = `/${PRODUCTS}/${ID}/${EDIT}/`
export const PRODUCT_DETAIL_PATH = `/${PRODUCTS}/${ID}/`
export const PRODUCT_EDIT_URL = `/${PRODUCTS}/%d/${EDIT}/`
export const PRODUCT_DETAIL_URL = `/${PRODUCTS}/%d/`

// Finance
const FINANCE = 'finance'
export const FINANCE_LIST_PATH = `/${FINANCE}/`
