import { filter, propOr, toPairs } from 'ramda'

export const excelRows = {
  productType: 'brick code',
  description: 'Описание',
  name: 'Вторичная торговая марка',
  tradeMark: 'Торговая марка',
  package: 'Тип упаковки',
  quantity: 'Количество изделий',
  measurement: 'Единица измерения',
  targetMarket: 'Целевой рынок'
}

export const getHTMLFromErrorData = (data) => {
  const excelKey = propOr([], 'excel', data)
  const targetMarketKey = propOr([], 'targetMarket', data)

  if (typeof targetMarketKey[0] === 'string') {
    return targetMarketKey
  }
  if (typeof excelKey[0] === 'string') {
    return excelKey
  } else {
    const mappedExcel = excelKey.map((item, index) => {
      if (JSON.stringify(item) !== '{}') {
        const currentExcelRow = index + 4

        const pairedItem = toPairs(item)

        const mappedExcelColumns = pairedItem.map((pItem) => {
          const keyName = propOr('', '0', pItem)
          const keyString = propOr('Неизвестная строка', keyName, excelRows)

          if (pItem) {
            return `- ${keyString}: ${propOr('', '1', pItem)}`
          }
          return null
        })

        const filteredExcelColumns = filter(fItem => !!fItem, mappedExcelColumns)
        const excelColumns = filteredExcelColumns.join('<br>')

        return `Ряд №${currentExcelRow}:<br>` +
          `${excelColumns}`
      }
      return null
    })

    const filteredExcel = filter(fItem => !!fItem, mappedExcel)

    return filteredExcel.join('<br>')
  }
}
