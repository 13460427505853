import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, Stack } from '@chakra-ui/react'

function TableHeader (props) {
  const { children, title } = props

  return (
    <Box mb={12}>
      <Flex align={'center'} justify={'space-between'}>
        <Heading fontSize={'3xl'} fontWeight={'semibold'}>
          {title}
        </Heading>

        <Stack align={'center'} direction={'row'}>
          {children}
        </Stack>
      </Flex>
    </Box>
  )
}

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default TableHeader
