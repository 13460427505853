import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Box, Button, Flex, Icon, Stack } from '@chakra-ui/react'
import { Logout } from 'react-iconly'

import UserInfo from './UserInfo'
import NavItem from './NavItem'

import nav from '~/constants/nav'
import { useAuth } from '~/contexts/AuthProvider'
import Logo from '~/components/Logo'

export function SideBar (props) {
  const { activeNav, width } = props

  const { onLogout } = useAuth()

  return (
    <Box
      bgColor={'bg.100'}
      borderColor={'custom.border'}
      borderRightWidth={1}
      py={5}
      pos={'fixed'}
      top={0}
      left={0}
      bottom={0}
      zIndex={10}>
      <Box height={'full'} w={width}>
        <Flex direction={'column'} overflowY={'auto'}>
          <Box flexGrow={'1'} pb={20}>
            <Stack pl={'custom'} pr={6} spacing={14}>
              <Logo />

              <UserInfo />
            </Stack>

            <Stack mt={10} spacing={0}>
              {nav.map(navItem => {
                const key = prop('key', navItem)
                const isActive = activeNav === key

                return (
                  <NavItem key={key} isActive={isActive} {...navItem} />
                )
              })}
            </Stack>
          </Box>

          <Stack
            as={Button}
            align={'center'}
            direction={'row'}
            justifyContent={'unset'}
            h={'auto'}
            pl={'custom'}
            pr={4}
            py={3}
            spacing={4}
            variant={'unstyled'}
            _hover={{
              bg: 'custom.lightGray',
              color: 'secondary.500'
            }}
            onClick={onLogout}>
            <Icon as={Logout} boxSize={'18px'} />
            <Box as={'span'}>Выйти</Box>
          </Stack>
        </Flex>
      </Box>
    </Box>
  )
}

SideBar.propTypes = {
  activeNav: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
}
