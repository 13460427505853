import {
  Discovery,
  Calendar
  // TimeCircle,
  // Chat
} from 'react-iconly'

import * as ROUTES from './routes'

export const NAV_KEYS = {
  FINANCE: 'finance',
  PRODUCTS: 'products',
  DOCUMENTS: 'documents',
  KNOWLEDGE: 'knowledge',
  CHAT: 'chat'
}

export default [
  {
    key: NAV_KEYS.FINANCE,
    label: 'Финансы',
    href: ROUTES.FINANCE_LIST_PATH,
    icon: Discovery
  },
  {
    key: NAV_KEYS.PRODUCTS,
    label: 'Продукция',
    href: ROUTES.PRODUCT_LIST_PATH,
    icon: Calendar
  }
  // {
  //   key: NAV_KEYS.KNOWLEDGE,
  //   label: 'База знаний',
  //   href: '/knowledge-base',
  //   icon: TimeCircle
  // },
  // {
  //   key: NAV_KEYS.CHAT,
  //   label: 'Чат',
  //   href: '/chat',
  //   icon: Chat
  // }
]
