import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export default function FooterCopyright () {
  return (
    <Box borderTop={'1px solid'} borderColor={'custom.border'} py={5}>
      <Text color={'text.100'} fontSize={'xs'} align={'center'}>
        © Copyright 2015 - GS1 Uzbekistan является членом Международной организации GS1 и представляет ее в Узбекистане
        с 1998 года.
      </Text>
    </Box>
  )
}
