export default {
  baseStyle: {
    header: {
      pt: 12,
      px: 9,
      pb: 6
    },
    body: {
      px: 9,
      py: 6
    },
    footer: {
      px: 9,
      pb: 6
    }
  }
}
