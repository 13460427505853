import { defaultTo, pipe, replace } from 'ramda'

export function toNumber (value) {
  const parsedStr = Number(value)
  return isNaN(parsedStr) ? 0 : parsedStr
}

export function numberFormat (value, suffix, options) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2,
    ...options
  })

  const number = pipe(
    String,
    defaultTo(''),
    replace(',', '.'),
    toNumber
  )(value)

  if (number && suffix) return `${formatter.format(number)} ${suffix}`

  if (number) return formatter.format(number)

  return '0'
}
