import React from 'react'
import PropTypes from 'prop-types'
import { Box, SimpleGrid, Stack } from '@chakra-ui/react'

const Text = props => (
  <Box bgColor={'bg.100'} zIndex={1} {...props} />
)

function DetailValue (props) {
  const { label, children, variant } = props

  if (variant === 'block') {
    return (
      <Stack spacing={2}>
        <Box color={'text.200'}>{label}</Box>
        <Box>{children}</Box>
      </Stack>
    )
  }

  return (
    <SimpleGrid
      alignItems={'flex-end'}
      columns={2}
      pos={'relative'}
      spacing={6}>
      <Text color={'text.200'} maxW={36}>{label}</Text>
      <Text justifySelf={'flex-end'} textAlign={'right'}>
        {children}
      </Text>

      <Box
        bgGradient={'linear(to-r, custom.border 40%, transparent 20%)'}
        bgPos={'top'}
        bgSize={'10px 1px'}
        bgRepeat={'repeat-x'}
        h={'1px'}
        pos={'absolute'}
        left={0}
        right={0}
        bottom={0}
        zIndex={0}
      />
    </SimpleGrid>
  )
}

DetailValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['inline', 'block'])
}

DetailValue.defaultProps = {
  variant: 'inline'
}

export default DetailValue
