import React from 'react'
import PropTypes from 'prop-types'
import { IconlyProvider } from 'react-iconly'

import { AuthProvider } from '~/contexts/AuthProvider'
import { MenuProvider } from '~/contexts/MenuProvider'

function BaseProvider ({ children }) {
  return (
    <AuthProvider>
      <IconlyProvider set={'light'}>
        <MenuProvider>
          {children}
        </MenuProvider>
      </IconlyProvider>
    </AuthProvider>
  )
}

BaseProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default BaseProvider
