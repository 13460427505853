/* eslint-disable max-len */
import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function Plus (props) {
  return (
    <Icon boxSize={6} viewBox={'0 0 14 14'} fill={'none'} {...props}>
      <path d={'M7 12V2'} stroke={'currentColor'} strokeWidth={'1.5'} strokeLinecap={'round'} />
      <path d={'M2 7H12'} stroke={'currentColor'} strokeWidth={'1.5'} strokeLinecap={'round'} />
    </Icon>
  )
}
