import React from 'react'
import { Box, IconButton } from '@chakra-ui/react'

import Plus from '~/icons/Plus'
import Link from '~/components/Link'

export default function CreateButton (props) {
  return (
    <Box>
      <IconButton
        as={Link}
        aria-label={'Create'}
        borderRadius={'full'}
        colorScheme={'secondary'}
        icon={<Plus boxSize={4} />}
        ml={8}
        to={'create'}
        size={'md'}
        {...props}
      />
    </Box>
  )
}
