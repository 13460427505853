import { fromPairs, join, map, pipe, split, toPairs } from 'ramda'

import { API_BASE_URL } from '~/constants/api'

export function searchToQuery (search) {
  const [, splitSearch] = split('?', search)
  const searchToObject = pipe(
    split('&'),
    map(split('=')),
    fromPairs
  )
  return splitSearch ? searchToObject(splitSearch) : {}
}

export function queryToSearch (query) {
  return pipe(
    toPairs,
    map(join('=')),
    join('&')
  )(query)
}

export function getDocumentLink (api, params) {
  const apiUrl = `${API_BASE_URL}${api}`
  const formedParams = queryToSearch({ ...params })
  const link = `${apiUrl}?${formedParams}`

  window.open(link, '_blank')
}
