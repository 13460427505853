import React from 'react'
import PropTypes from 'prop-types'
import { ChevronDown } from 'react-iconly'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Flex,
  Icon as ChakraIcon,
  Stack,
  Collapse,
  useDisclosure
} from '@chakra-ui/react'

import Link from '~/components/Link'

function NavItem (props) {
  const { href, label, icon: Icon, children, isActive } = props

  const { isOpen, onToggle } = useDisclosure()

  const activeStyleProps = {
    bgColor: isActive ? 'custom.lightGray' : 'transparent',
    color: isActive ? 'secondary.500' : 'inherit',
    fontWeight: isActive ? 'medium' : 'normal',
    _hover: { color: 'secondary.500' }
  }

  const styleProps = {
    cursor: 'pointer',
    lineHeight: 'normal',
    pl: 'custom',
    pr: 4,
    py: 3,
    transition: 'all 200ms'
  }

  if (children) {
    const isOpenCollapse = isOpen || isActive

    return (
      <Stack spacing={0}>
        <Flex
          align={'center'}
          justify={'space-between'}
          onClick={onToggle}
          {...activeStyleProps}
          {...styleProps}>
          <Stack align={'center'} direction={'row'} spacing={4}>
            <ChakraIcon as={Icon} boxSize={'18px'} />
            <Box as={'span'}>{label}</Box>
          </Stack>

          <ChakraIcon
            as={ChevronDown}
            boxSize={4}
            transition={'all .25s ease-in-out'}
            transform={isOpenCollapse ? 'rotate(180deg)' : ''}
          />
        </Flex>
        <Collapse in={isOpenCollapse} animateOpacity={true}>
          <Stack
            align={'start'}
            color={'text.200'}
            pl={'custom'}
            py={2}
            spacing={4}>
            {children.map((child, index) => (
              <Link
                {...styleProps}
                as={NavLink}
                activeClassName={'active'}
                key={index}
                to={child.href}
                pl={12}
                py={0}
                w={'full'}
                sx={{
                  '&.active': {
                    color: 'secondary.500',
                    pointerEvents: 'none'
                  }
                }}
                _hover={{ color: 'text.300' }}>
                {child.label}
              </Link>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  return (
    <Link to={href} {...activeStyleProps} {...styleProps}>
      <Stack align={'center'} direction={'row'} spacing={4}>
        <ChakraIcon as={Icon} boxSize={'18px'} />
        <Box as={'span'}>{label}</Box>
      </Stack>
    </Link>
  )
}

NavItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.array,
  isActive: PropTypes.bool
}

export default NavItem
