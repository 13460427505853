import format from 'date-fns/format'
import { ru } from 'date-fns/locale'

function formatDate (dateString, formatString = 'dd.MM.yyyy') {
  if (!dateString) return null

  return format(new Date(dateString), formatString, {
    locale: ru
  })
}

export function useDateFormat () {
  function dateFormat (date, format) {
    return formatDate(date, format)
  }

  return { dateFormat }
}
