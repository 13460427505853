import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'ramda'

import { Col } from '~/components/Grid'

function TableCol (props) {
  const { children, isHead, ...restProps } = props

  const contentIsString = is(String, children)

  return (
    <Col
      noOfLines={isHead ? 2 : undefined}
      title={(isHead && contentIsString) ? children : undefined}
      {...restProps}>
      {children}
    </Col>
  )
}

TableCol.propTypes = {
  children: PropTypes.node,
  isHead: PropTypes.bool
}

export default TableCol
