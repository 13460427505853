import { useToast as useChakraToast } from '@chakra-ui/react'

export const SUCCESS_CREATE_PARAMS = {
  status: 'success',
  title: 'Готово',
  description: 'Успешно создано'
}

export const SUCCESS_UPDATE_PARAMS = {
  status: 'success',
  title: 'Готово',
  description: 'Успешно отредактировано'
}

export default function useToast (params) {
  return useChakraToast({
    duration: 5000,
    isClosable: true,
    position: 'top-left',
    variant: 'solid',
    ...params
  })
}
