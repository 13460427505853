import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Delete } from 'react-iconly'
import { Box, Flex, Icon, Image, Link, Stack } from '@chakra-ui/react'

import { humanizeFileSize } from '~/utils/file'

function FileImageItem (props) {
  const { file, onClear } = props

  const fileId = prop('id', file)
  const fileName = prop('filename', file)
  const filePath = prop('file', file)
  const fileSize = humanizeFileSize(prop('size', file))

  return (
    <Flex
      align={'center'}
      bgColor={'custom.lightGray'}
      borderRadius={'sm'}
      justify={'space-between'}
      p={3}>
      <Stack align={'center'} direction={'row'} spacing={3}>
        <Box
          bgColor={'white'}
          borderRadius={'sm'}
          h={12}
          minW={20}
          w={20}
          overflow={'hidden'}>
          <Image
            src={filePath}
            objectFit={'cover'}
            objectPosition={'center'}
            h={'full'}
            w={'full'}
          />
        </Box>

        <Stack spacing={1}>
          <Box color={'primary.900'} fontSize={'sm'} noOfLines={1}>
            <Link href={filePath} isExternal={true}>
              {fileName}
            </Link>
          </Box>
          <Box color={'text.100'} fontSize={'xs'}>
            {fileSize}
          </Box>
        </Stack>
      </Stack>

      <Icon
        as={Delete}
        boxSize={5}
        color={'primary.900'}
        cursor={'pointer'}
        onClick={onClear.bind(null, fileId)}
      />
    </Flex>
  )
}

FileImageItem.propTypes = {
  file: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired
}

export default FileImageItem
