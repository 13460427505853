import React, { Fragment } from 'react'
import { Flex, Button, Stack } from '@chakra-ui/react'

import { useLogin } from '../hooks'

import LoginHeading from './LoginHeading'
import LoginGCPField from './LoginGCPField'
import LoginCodeField from './LoginCodeField'

import { HookForm } from '~/components/HookForm'

export default function Login () {
  const { step, onSendCode, onSendSMS, codeLoading, smsLoading } = useLogin()

  return (
    <Stack spacing={5} w={'full'}>
      <LoginHeading>Авторизация</LoginHeading>

      <Flex
        bgColor={'white'}
        borderRadius={'base'}
        boxShadow={'base'}
        direction={'column'}
        justify={'center'}
        p={6}
        minH={'2xs'}>
        <Fragment>
          {step === 0 && (
            <HookForm onSubmit={onSendSMS}>
              <Stack spacing={4}>
                <LoginGCPField />
                <Button
                  alignSelf={'baseline'}
                  colorScheme={'secondary'}
                  type={'submit'}
                  size={'md'}
                  isLoading={smsLoading}>
                  Отправить
                </Button>
              </Stack>
            </HookForm>
          )}
          {step === 1 && (
            <HookForm onSubmit={onSendCode}>
              <Stack spacing={4}>
                <LoginCodeField />
                <Button
                  alignSelf={'baseline'}
                  colorScheme={'secondary'}
                  type={'submit'}
                  size={'md'}
                  isLoading={codeLoading}>
                  Отправить
                </Button>
              </Stack>
            </HookForm>
          )}
        </Fragment>
      </Flex>
    </Stack>
  )
}
