/* eslint-disable */
import React from 'react'
import { prop } from 'ramda'
import { Box, Container, Link, HStack, Stack } from '@chakra-ui/react'

import FooterCopyright from './FooterCopyrght'

import { WEBSITE_NAV_TITLES } from '~/constants/websiteNav'
import { getMenuItemUrl } from '~/utils/menu'
import Logo from '~/components/Logo'
import { useTranslate } from '~/utils/translate'
import { useAppMenu } from '~/contexts/MenuProvider'

const visibleMenuGroups = [
  'aboutUs',
  'standardsSolutions',
  'industry',
  'association'
]

export default function Footer() {
  const { translateData } = useTranslate()

  const { menu } = useAppMenu()

  const pairedMenu = Object.entries(menu).filter(([key, children]) => {
    return children.length && visibleMenuGroups.includes(key)
  })

  return (
    <Box bgColor={'white'} borderTop={'1px solid'} borderColor={'gray.200'}>
      <Container maxW={'container.lg'}>
        <HStack align={'unset'} py={9} spacing={16}>
          <Logo width={'100px'} />

          {pairedMenu.map(([key, children]) => {
            const title = prop(key, WEBSITE_NAV_TITLES)

            return (
              <Stack key={key} spacing={3}>
                <Box color={'primary.500'} fontWeight={'medium'}>
                  {title}
                </Box>
                {children.map(item => {
                  const url = getMenuItemUrl(item)

                  return (
                    <Link
                      key={item.key}
                      color={'#008dbd'}
                      fontSize={'sm'}
                      isExternal={true}
                      href={url}>
                      {translateData(item, 'title')}
                    </Link>
                  )
                })}
              </Stack>
            )
          })}
        </HStack>

        <FooterCopyright />
      </Container>
    </Box>
  )
}
