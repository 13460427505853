export default {
  global: () => ({
    body: {
      bg: 'white',
      color: 'text.500',
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 'normal',
      minW: 'container.xl'
    },
    '*::placeholder': {
      color: 'text.100'
    }
  })
}
