import React from 'react'
import { useHistory } from 'react-router-dom'

import { productCreateSerializer } from '../serializers'
import { useProductCreate } from '../hooks'
import { ProductCreate } from '../components/ProductCreate'

import useToast, { SUCCESS_CREATE_PARAMS } from '~/hooks/app/useToast'
import AppBarSimple from '~/components/AppBarSimple'

export default function ProductCreateContainer () {
  const history = useHistory()

  const toast = useToast()

  const { create, isLoading } = useProductCreate()

  function onSubmit (values) {
    return create(productCreateSerializer(values))
      .then(() => toast(SUCCESS_CREATE_PARAMS))
      .then(() => history.goBack())
  }

  return (
    <AppBarSimple>
      <ProductCreate
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </AppBarSimple>
  )
}
