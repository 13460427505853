export default {
  defaultProps: {
    colorScheme: 'secondary'
  },
  baseStyle: {
    control: {
      transition: 'all 200ms',
      _focus: { boxShadow: 'none' }
    }
  }
}
