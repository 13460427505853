import { prop, path } from 'ramda'
import { sprintf } from 'sprintf-js'

import { capitalize } from './string'

function translate (key, locale = 'ru') {
  return path([key, locale], {})
}

export function useTranslate () {
  const locale = 'ru'

  function t (key, params) {
    return sprintf(translate(key, locale), params)
  }

  function translateData (obj, objName) {
    const name = objName + capitalize(locale)
    return prop(name, obj) || prop(objName, obj)
  }

  return { t, translateData }
}
