import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { map, pipe, prop, propOr } from 'ramda'

import { productCreateSerializer } from '../serializers'
import { useProductDetail, useProductEdit } from '../hooks'
import { ProductCreate } from '../components/ProductCreate'

import useToast, { SUCCESS_UPDATE_PARAMS } from '~/hooks/app/useToast'
import AppBarSimple from '~/components/AppBarSimple'

export default function ProductEditContainer () {
  const { id } = useParams()
  const history = useHistory()
  const toast = useToast()
  const { data, isLoading: isLoadingData } = useProductDetail(id)
  const { update, isLoading } = useProductEdit(id)

  function onSubmit (formValues) {
    return update(productCreateSerializer(formValues))
      .then(() => toast(SUCCESS_UPDATE_PARAMS))
      .then(() => history.goBack())
  }

  const defaultValues = {
    ...data,
    packages: pipe(
      propOr([], 'packages'),
      map(pack => ({
        ...pack,
        measurement: { id: prop('measurement', pack) }
      }))
    )(data)
  }

  return (
    <AppBarSimple>
      <ProductCreate
        onSubmit={onSubmit}
        isEdit={true}
        isLoading={isLoading}
        isLoadingData={isLoadingData}
        defaultValues={defaultValues}
      />
    </AppBarSimple>
  )
}
