import React from 'react'
import PropTypes from 'prop-types'
import { always, defaultTo, isEmpty, mapObjIndexed, not } from 'ramda'
import { FormProvider, useForm } from 'react-hook-form'
import { Box } from '@chakra-ui/react'

import useSubmitForm from './useSubmitForm'

import { useToast } from '~/hooks/app'

function HookForm (props) {
  const {
    defaultValues,
    children,
    onSubmit,
    mode,
    render,
    resetOnSuccess
  } = props

  const toast = useToast()

  const methods = useForm({
    mode,
    reValidateMode: 'onBlur',
    defaultValues
  })

  const { getSubmitErrors } = useSubmitForm()

  function handleSubmitForm (values) {
    return onSubmit(values)
      .then(() => {
        if (resetOnSuccess) {
          const touchedFields = methods.formState.touchedFields
          methods.reset(mapObjIndexed(always(''), touchedFields))
        }
      })
      .catch(e => {
        const { nonFieldError, errors } = getSubmitErrors(e)

        if (not(isEmpty(errors))) {
          mapObjIndexed((value, key) => {
            methods.setError(key, {
              type: 'manual',
              message: value
            })
          }, errors)
        }

        if (nonFieldError) {
          const nonFieldErrorMessage = defaultTo('Unable to connect to server', nonFieldError)

          toast({
            title: 'Error',
            description: nonFieldErrorMessage,
            status: 'error'
          })
        }
      })
  }

  const onSubmitForm = (event) => {
    return methods.handleSubmit(handleSubmitForm)(event)
  }

  if (typeof render === 'function') {
    return (
      <FormProvider {...methods}>
        {render({ ...methods, onSubmitForm })}
      </FormProvider>
    )
  }

  return (
    <FormProvider {...methods}>
      <Box as={'form'} onSubmit={onSubmitForm}>
        {children}
      </Box>
    </FormProvider>
  )
}

HookForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  defaultValues: PropTypes.object,
  mode: PropTypes.oneOf([
    'all',
    'onBlur',
    'onSubmit',
    'onChange',
    'onTouched'
  ]),
  render: PropTypes.func,
  resetOnSuccess: PropTypes.bool
}

HookForm.defaultProps = {
  mode: 'onBlur'
}

export default HookForm
