import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Stack } from '@chakra-ui/react'
import { equals, filter, includes, isEmpty, length, not, pipe, prop } from 'ramda'

import { useTable } from './TableProvider'
import TableHeadRow from './TableHeadRow'

import { Row } from '~/components/Grid'

function CheckboxWrapper (props) {
  return (
    <Stack
      align={'center'}
      direction={'row'}
      pl={4}
      w={'full'}
      {...props}
    />
  )
}

function TableRowRender (props) {
  const { rowMinHeight, spacing } = useTable()

  return (
    <Row
      align={'center'}
      minH={rowMinHeight}
      spacing={spacing}
      {...props}>
    </Row>
  )
}

function TableRow (props) {
  const { children, id, isBody, onClick, ...restProps } = props

  const {
    isLoading,
    isSelectable,
    list,
    checkedList,
    onCheckRow
  } = useTable()

  const paddings = { px: 5, py: 2 }

  const borderProps = {
    pos: 'relative',
    _notLast: {
      _after: {
        borderColor: 'custom.border',
        borderBottomWidth: 1,
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-1px',
        transition: 'inherit'
      }
    }
  }

  const displayCheckboxes = isSelectable && !isLoading

  if (isBody) {
    const clickableProps = onClick
      ? {
        cursor: 'pointer',
        transition: 'all 200ms',
        _hover: {
          _after: {
            opacity: 0
          }
        }
      }
      : {}

    const columns = Children.map(children, (child, key) => {
      return cloneElement(child, { key })
    })

    if (displayCheckboxes) {
      const stringId = String(id)
      const isChecked = !!id && not(isEmpty(checkedList)) && includes(stringId, checkedList)

      const onChangeCheckbox = (event) => {
        const checked = event.target.checked
        if (checked) {
          onCheckRow([...checkedList, stringId])
        } else {
          const filteredCheckList = filter(pipe(equals(stringId), not), checkedList)
          onCheckRow(filteredCheckList)
        }
      }

      return (
        <CheckboxWrapper
          bgColor={isChecked ? 'gray.50' : 'inherit'}
          {...borderProps}>
          <Checkbox
            isChecked={isChecked}
            onChange={onChangeCheckbox}
          />
          <TableRowRender
            onClick={onClick}
            flexGrow={1}
            {...clickableProps}
            {...paddings}
            {...restProps}>
            {columns}
          </TableRowRender>
        </CheckboxWrapper>
      )
    }

    return (
      <TableRowRender
        onClick={onClick}
        {...borderProps}
        {...clickableProps}
        {...paddings}
        {...restProps}>
        {columns}
      </TableRowRender>
    )
  }

  const columns = Children.map(children, (child, key) => {
    return cloneElement(child, { key, isHead: true })
  })

  if (displayCheckboxes) {
    const listIds = list.map(pipe(prop('id'), String))
    const hasCheckedValue = not(isEmpty(checkedList))
    const isChecked = hasCheckedValue && length(list) === length(checkedList)
    const isIndeterminate = hasCheckedValue && length(list) !== length(checkedList)

    const onChangeCheckbox = (event) => {
      const checked = event.target.checked

      if (checked) {
        onCheckRow(listIds)
      } else {
        onCheckRow([])
      }
    }

    return (
      <CheckboxWrapper>
        <Checkbox
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          onChange={onChangeCheckbox}
        />
        <TableHeadRow flexGrow={1} {...paddings}>
          {columns}
        </TableHeadRow>
      </CheckboxWrapper>
    )
  }

  return (
    <TableHeadRow {...paddings}>
      {columns}
    </TableHeadRow>
  )
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.any,
  isBody: PropTypes.bool,
  onClick: PropTypes.func
}

export default TableRow
