import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chakra-ui/react'

import { SideBar } from './SideBar'

import { NAV_KEYS } from '~/constants/nav'

function AppBar (props) {
  const { children, activeNav } = props

  const sideBarWidth = 270

  return (
    <Flex h={'100vh'}>
      <SideBar
        activeNav={activeNav}
        width={sideBarWidth}
      />
      <Box flexGrow={1} pl={sideBarWidth}>
        <Box
          alignSelf={'baseline'}
          h={'full'}
          pl={8}
          pr={'custom'}
          py={10}>
          {children}
        </Box>
      </Box>
    </Flex>
  )
}

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
  activeNav: PropTypes.oneOf(Object.values(NAV_KEYS))
}

export default AppBar
