import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import AppRoutes from './AppRoutes'

import { ROOT_PATH, PRODUCT_LIST_PATH } from '~/constants/routes'
import routes from '~/routes'

export default function AppRouter () {
  return (
    <Switch>
      {routes.map((route, key) => (
        <AppRoutes key={key} {...route} />
      ))}
      <Redirect from={ROOT_PATH} to={PRODUCT_LIST_PATH} />
    </Switch>
  )
}
