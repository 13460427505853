import React from 'react'
import { Box, Container, Link, Stack } from '@chakra-ui/react'

import { WEBSITE_NAV_TITLES } from '~/constants/websiteNav'
import { getMenuItemUrl } from '~/utils/menu'
import { useAppMenu } from '~/contexts/MenuProvider'

export default function Navigation () {
  const { menu } = useAppMenu()

  if (!Object.entries(menu).length) return null

  return (
    <Box as={'nav'} bgColor={'white'} boxShadow={'md'} py={5}>
      <Container maxW={'container.lg'}>
        <Stack direction={'row'} spacing={6}>
          {Object.entries(menu).map(([key, children]) => {
            const title = WEBSITE_NAV_TITLES[key]
            const firstChildUrl = getMenuItemUrl(children[0])

            return (
              <Link
                key={key}
                borderRadius={'base'}
                color={'primary.500'}
                display={'block'}
                fontSize={'lg'}
                fontWeight={'medium'}
                href={firstChildUrl}
                isExternal={true}
                lineHeight={'none'}
                py={2}
                px={3}
                _hover={{ bgColor: 'primary.100' }}>
                {title}
              </Link>
            )
          })}
        </Stack>
      </Container>
    </Box>
  )
}
