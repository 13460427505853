import React from 'react'

import { useFinanceList } from '../hooks'
import { FinanceList } from '../components/FinanceList'

import { NAV_KEYS } from '~/constants/nav'
import AppBar from '~/components/AppBar'

export default function FinanceListContainer () {
  const list = useFinanceList()

  return (
    <AppBar activeNav={NAV_KEYS.FINANCE}>
      <FinanceList list={list} />
    </AppBar>
  )
}
