import { LoginStepperProvider } from './components/LoginStepperProvider'

import * as ROUTES from '~/constants/routes'
import { AuthLayout } from '~/components/Layouts'

export default [
  {
    path: ROUTES.LOGIN_PATH,
    layout: AuthLayout,
    component: LoginStepperProvider
  }
]
