import React from 'react'
import { IconButton } from '@chakra-ui/react'

import ExcelDownload from '~/icons/ExcelDownload'

export default function ExcelDownloadButton (props) {
  return (
    <IconButton
      aria-label={'FaFileDownload'}
      bg={'white'}
      borderColor={'custom.border'}
      borderWidth={1}
      borderRadius={'full'}
      colorScheme={'gray'}
      icon={<ExcelDownload boxSize={4} color={'text.100'} />}
      size={'md'}
      _hover={{ bg: 'bg.100' }}
      _active={{ bg: 'bg.200' }}
      {...props}
    />
  )
}
