import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

function AppRoutes (route) {
  return (
    <Fragment>
      <Route
        exact={true}
        path={route.path}
        render={props => (
          <route.layout>
            <route.component {...props} />
          </route.layout>
        )}
      />
      {route.routes.map((route, index) => (
        <AppRoutes key={index} {...route} />
      ))}
    </Fragment>
  )
}

AppRoutes.propTypes = {
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  layout: PropTypes.PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  routes: PropTypes.array
}

AppRoutes.defaultProps = {
  routes: []
}

export default AppRoutes
