import { extendTheme } from '@chakra-ui/react'

import colors from './colors'
import components from './components'
import styles from './styles'

const fonts = {
  body: 'SFProDisplay, sans-serif',
  heading: 'SFProDisplay, sans-serif'
}

const space = {
  custom: '70px'
}

const sizes = {
  custom: space.custom
}

export default extendTheme({
  colors,
  components,
  fonts,
  sizes,
  space,
  styles
})
