import { useHistory, useLocation } from 'react-router-dom'

import { queryToSearch, searchToQuery } from '~/utils/url'

export default function useAddParamsURL (method = 'replace') {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const searchQuery = searchToQuery(search)

  return function (newParams) {
    const newPath = {
      pathname,
      search: queryToSearch({
        ...searchQuery,
        ...newParams
      })
    }

    if (method === 'replace') {
      history.replace(newPath)
    } else if (method === 'push') {
      history.push(newPath)
    }
  }
}
