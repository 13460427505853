import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Box, Text } from '@chakra-ui/react'

import { useTable } from './TableProvider'

import { TableSkeleton } from '~/components/Skeletons'

function TableContainer (props) {
  return (
    <Box mt={4} {...props} />
  )
}

function Table ({ children }) {
  const { isLoading, list } = useTable()

  if (isLoading) {
    return (
      <TableContainer>
        <TableSkeleton />
      </TableContainer>
    )
  }

  if (list && isEmpty(list)) {
    return (
      <TableContainer>
        {children}
        <Box rounded={'md'} p={8}>
          <Text fontSize={'lg'} textAlign={'center'}>
            No data to display
          </Text>
        </Box>
      </TableContainer>
    )
  }

  return (
    <TableContainer>
      {children}
    </TableContainer>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired
}

export default Table
