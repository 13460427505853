/* eslint-disable max-len */
import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function XMarkCircle (props) {
  return (
    <Icon viewBox={'0 0 14 15'} fill={'currentColor'} {...props}>
      <path d={'M6.99657 14.4999C7.95742 14.4999 8.85963 14.3171 9.70319 13.9515C10.5468 13.5859 11.2899 13.0827 11.9328 12.4418C12.5756 11.801 13.0808 11.0563 13.4485 10.2078C13.8161 9.35932 14 8.45668 14 7.49988C14 6.54307 13.8161 5.64269 13.4485 4.79871C13.0808 3.95474 12.5756 3.21006 11.9328 2.56467C11.2899 1.91928 10.5456 1.4138 9.69981 1.04823C8.85399 0.682662 7.95066 0.499878 6.98981 0.499878C6.03347 0.499878 5.13352 0.682662 4.28995 1.04823C3.44639 1.4138 2.7032 1.91928 2.06038 2.56467C1.41755 3.21006 0.913446 3.95474 0.548051 4.79871C0.182658 5.64269 -3.8147e-05 6.54307 -3.8147e-05 7.49988C-3.8147e-05 8.45668 0.182658 9.35932 0.548051 10.2078C0.913444 11.0563 1.41868 11.801 2.06376 12.4418C2.70884 13.0827 3.45316 13.5859 4.29672 13.9515C5.14028 14.3171 6.04023 14.4999 6.99657 14.4999ZM4.64858 10.4989C4.46814 10.4989 4.31589 10.4357 4.19184 10.3093C4.06778 10.183 4.00576 10.0295 4.00576 9.84899C4.00576 9.67298 4.06891 9.52404 4.19522 9.40218L6.08309 7.50663L4.19522 5.61785C4.06891 5.496 4.00576 5.34706 4.00576 5.17104C4.00576 4.99052 4.06778 4.83819 4.19184 4.71408C4.31589 4.58997 4.46814 4.52791 4.64858 4.52791C4.83353 4.52791 4.98691 4.59109 5.10871 4.71746L6.98981 6.59948L8.89121 4.7107C9.01752 4.57981 9.17089 4.51437 9.35134 4.51437C9.53178 4.51437 9.68402 4.57756 9.80808 4.70393C9.93213 4.8303 9.99416 4.98375 9.99416 5.16427C9.99416 5.34029 9.931 5.48923 9.8047 5.61108L7.91006 7.50663L9.79793 9.38865C9.92424 9.52404 9.98739 9.67749 9.98739 9.84899C9.98739 10.0295 9.92537 10.183 9.80131 10.3093C9.67726 10.4357 9.52501 10.4989 9.34457 10.4989C9.16413 10.4989 9.0085 10.4357 8.87768 10.3093L6.98981 8.42056L5.11548 10.3093C4.99368 10.4357 4.83805 10.4989 4.64859 10.4989L4.64858 10.4989Z'} />
    </Icon>
  )
}
