import { FinanceListContainer } from './containers'

import * as ROUTES from '~/constants/routes'
import { ListLayout } from '~/components/Layouts'

export default [
  {
    path: ROUTES.FINANCE_LIST_PATH,
    layout: ListLayout,
    component: FinanceListContainer
  }
]
