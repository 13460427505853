import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import { Edit } from 'react-iconly'
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Icon,
  Stack,
  useDisclosure
} from '@chakra-ui/react'

function DetailDrawer (props) {
  const { id, idLabel = id, title, children, editUrl, displayId } = props

  const history = useHistory()

  const { isOpen } = useDisclosure({ isOpen: !!id })

  function onClose () {
    history.goBack()
  }

  function onEdit () {
    if (editUrl) {
      history.push(sprintf(editUrl, id))
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      placement={'right'}
      preserveScrollBarGap={true}
      returnFocusOnClose={false}
      size={'md'}>
      <DrawerOverlay />

      <DrawerContent bgColor={'bg.100'}>
        <DrawerHeader fontSize={'2xl'} fontWeight={'medium'} pr={'custom'}>
          <Stack spacing={'2px'}>
            <Flex align={'center'} justify={'space-between'}>
              <Box>{title}</Box>
              <CloseButton size={'lg'} onClick={onClose} />
            </Flex>
            {displayId && (
              <Box color={'text.200'} fontSize={'md'}>
                {idLabel}
              </Box>
            )}
          </Stack>
        </DrawerHeader>

        <DrawerBody pr={'custom'}>
          {children}
        </DrawerBody>

        <DrawerFooter justifyContent={'center'}>
          <Button
            bg={'bg.100'}
            borderColor={'#d5dce4'}
            borderRadius={'full'}
            borderWidth={1}
            color={'primary.900'}
            fontWeight={'normal'}
            height={9}
            rightIcon={<Icon as={Edit} boxSize={5} />}
            onClick={onEdit}
            _hover={{ bg: 'gray.100' }}
            _active={{ bg: 'gray.200' }}>
            Редактировать
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

DetailDrawer.propTypes = {
  id: PropTypes.any,
  idLabel: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  editUrl: PropTypes.string,
  displayId: PropTypes.bool
}

export default DetailDrawer
