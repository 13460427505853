/* eslint-disable max-len */
import React from 'react'
import { Icon } from '@chakra-ui/react'

export default function CheckMarkCircle (props) {
  return (
    <Icon viewBox={'0 0 14 15'} fill={'currentColor'} {...props}>
      <path d={'M6.99657 14.4999C7.95742 14.4999 8.85963 14.3171 9.70319 13.9515C10.5468 13.5859 11.2899 13.0827 11.9328 12.4418C12.5756 11.801 13.0808 11.0563 13.4485 10.2078C13.8161 9.35932 14 8.45668 14 7.49988C14 6.54307 13.8161 5.64269 13.4485 4.79871C13.0808 3.95474 12.5756 3.21006 11.9328 2.56467C11.2899 1.91928 10.5456 1.4138 9.69981 1.04823C8.85399 0.682662 7.95066 0.499878 6.98981 0.499878C6.03347 0.499878 5.13352 0.682662 4.28995 1.04823C3.44639 1.4138 2.7032 1.91928 2.06038 2.56467C1.41755 3.21006 0.913446 3.95474 0.548051 4.79871C0.182658 5.64269 -3.8147e-05 6.54307 -3.8147e-05 7.49988C-3.8147e-05 8.45668 0.182658 9.35932 0.548051 10.2078C0.913444 11.0563 1.41868 11.801 2.06376 12.4418C2.70884 13.0827 3.45316 13.5859 4.29672 13.9515C5.14028 14.3171 6.04023 14.4999 6.99657 14.4999ZM6.07632 11.0405C5.9455 11.0405 5.82821 11.0123 5.72446 10.9559C5.62071 10.8995 5.51921 10.8126 5.41996 10.6952L3.4712 8.31903C3.40804 8.24681 3.36068 8.16896 3.3291 8.08547C3.29752 8.00197 3.28173 7.91735 3.28173 7.8316C3.28173 7.65558 3.34489 7.50552 3.4712 7.38141C3.59751 7.25729 3.74637 7.19524 3.91779 7.19524C4.02606 7.19524 4.1253 7.21893 4.21552 7.26632C4.30574 7.31371 4.39145 7.3893 4.47265 7.49311L6.04925 9.49021L9.10773 4.60917C9.2566 4.36997 9.44155 4.25037 9.66259 4.25037C9.83401 4.25037 9.98739 4.30453 10.1227 4.41285C10.258 4.52117 10.3257 4.66559 10.3257 4.84612C10.3257 4.92735 10.3077 5.00972 10.2716 5.09322C10.2355 5.17671 10.1949 5.25457 10.1498 5.32678L6.71237 10.6817C6.63117 10.799 6.53644 10.8882 6.42817 10.9491C6.31991 11.01 6.20262 11.0405 6.07631 11.0405L6.07632 11.0405Z'} />
    </Icon>
  )
}
