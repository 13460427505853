import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box, Button, Container, Flex } from '@chakra-ui/react'

import Logo from '~/components/Logo'

function AppBarSimple (props) {
  const { children } = props

  const history = useHistory()

  return (
    <Box>
      <Flex
        align={'center'}
        borderBottom={'1px solid'}
        borderColor={'custom.border'}
        justify={'space-between'}
        mb={12}
        py={5}>
        <Logo />

        <Button
          color={'custom.blue'}
          h={'auto'}
          variant={'unstyled'}
          onClick={history.goBack}>
          Закрыть
        </Button>
      </Flex>

      <Container maxW={'container.sm'}>
        {children}
      </Container>
    </Box>
  )
}

AppBarSimple.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppBarSimple
