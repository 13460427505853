import React, { useEffect } from 'react'
import { path } from 'ramda'

import { useProductDetail, useProductList } from '../hooks'
import { ProductList } from '../components/ProductList'
import { ProductDetail } from '../components/ProductDetail'

import { NAV_KEYS } from '~/constants/nav'
import * as ROUTES from '~/constants/routes'
import { useAddParamsURL, useGetSearchParam } from '~/hooks/urls'
import AppBar from '~/components/AppBar'
import DetailDrawer from '~/components/DetailDrawer'

export default function ProductListContainer () {
  const getSearchParam = useGetSearchParam()
  const addParamsURL = useAddParamsURL('push')

  const id = getSearchParam('product')

  const list = useProductList()
  const productDetail = useProductDetail(id, { autoSend: false })

  function goDetail (id) {
    addParamsURL({ product: id })
  }

  useEffect(() => {
    if (id) productDetail.getDetail()
  }, [id])

  const detailGtin = path(['data', 'myEancode'], productDetail)

  return (
    <AppBar activeNav={NAV_KEYS.PRODUCTS}>
      <ProductList
        goDetail={goDetail}
        list={list}
      />

      <DetailDrawer
        id={id}
        idLabel={detailGtin}
        title={'GTIN-13'}
        editUrl={ROUTES.PRODUCT_EDIT_URL}
        displayId={true}
      >
        <ProductDetail detail={productDetail} />
      </DetailDrawer>
    </AppBar>
  )
}
