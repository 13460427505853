import { useState } from 'react'

import { useStepper } from '../components/LoginStepperProvider'

import { SEND_CODE, SEND_SMS } from '~/constants/api'
import { useToast } from '~/hooks/app'
import { useCreate } from '~/hooks/crud'
import { useAuth } from '~/contexts/AuthProvider'

export function useLogin () {
  const toast = useToast()

  const { onLogin } = useAuth()

  const sendSms = useCreate(SEND_SMS)
  const sendCode = useCreate(SEND_CODE)

  const [values, setValues] = useState({})

  const { step, toNextStep } = useStepper()

  function onSendSMS (formValues) {
    return sendSms.create(formValues)
      .then(() => {
        toast({
          title: 'Код отправлен',
          description: 'Введите код, отправленный на ваш номер',
          status: 'success'
        })

        setValues(prev => ({ ...prev, ...formValues }))
        toNextStep()
      })
      .catch(({ detail }) => {
        toast({
          title: 'Error',
          description: detail,
          status: 'error'
        })
      })
  }

  function onSendCode (formValues) {
    return sendCode.create({ ...values, ...formValues })
      .then(() => {
        onLogin()
      })
  }

  return {
    step,
    onSendSMS,
    onSendCode,
    smsLoading: sendSms.isLoading,
    codeLoading: sendCode.isLoading
  }
}
