import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import theme from '../theme/theme'

import BaseProvider from './BaseProvider'
import AppRouter from './AppRouter'
import Fonts from './Fonts'

export default function App () {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />

      <BrowserRouter>
        <BaseProvider>
          <AppRouter />
        </BaseProvider>
      </BrowserRouter>
    </ChakraProvider>
  )
}
