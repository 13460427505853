import React, { useState, createContext, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import request from '~/utils/request'
import { AuthSkeleton } from '~/components/Skeletons'

const AuthContext = createContext({})

export function AuthProvider ({ children }) {
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)

  function onLogout () {
    setLoading(true)
    request.post(API.LOGOUT)
      .finally(() => {
        history.replace({ pathname: ROUTES.LOGIN_PATH })
        setLoading(false)
        setUser(null)
      })
  }

  function fetchCurrentUser () {
    return request.get(API.ME)
      .then(({ data }) => {
        if (data?.isStaff) {
          onLogout()
        } else {
          setUser(data)
        }
      })
  }

  useEffect(() => {
    fetchCurrentUser()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        onLogout()
      })
  }, [])

  function onLogin () {
    history.replace(ROUTES.PRODUCT_LIST_PATH)

    return fetchCurrentUser()
  }

  const authProps = {
    isAuth: !!user,
    user,
    onLogin,
    onLogout
  }

  return (
    <AuthContext.Provider value={authProps}>
      {isLoading ? <AuthSkeleton /> : children}
    </AuthContext.Provider>
  )
}

export function useAuth () {
  return useContext(AuthContext)
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
