export function useSkeletonColors () {
  return {
    light: {
      startColor: 'gray.200',
      endColor: 'gray.400'
    },
    dark: {
      startColor: 'gray.500',
      endColor: 'gray.700'
    }
  }
}
