import React from 'react'
import { Box } from '@chakra-ui/react'

export default function TableHead (props) {
  return (
    <Box
      bgColor={'bg.200'}
      borderRadius={'base'}
      color={'text.200'}
      fontSize={'sm'}
      {...props}
    />
  )
}
