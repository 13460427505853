import React from 'react'
import { prop } from 'ramda'
import { Box, Stack } from '@chakra-ui/react'

import { useDateFormat } from '~/utils/date'
import { useAuth } from '~/contexts/AuthProvider'

export default function UserInfo () {
  const { user } = useAuth()

  const { dateFormat } = useDateFormat()

  const company = prop('company', user)
  const ownership = prop('ownership', company)
  const name = prop('name', company)
  const companyName = `${ownership} «${name}»`
  const gcp = prop('gcp', company)
  const gln = prop('gln', company)
  const endDate = dateFormat(prop('endDate', company))

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Box fontWeight={'semibold'}>{companyName}</Box>
        <Stack color={'text.200'} fontSize={'sm'} spacing={'2px'}>
          <Box>GCP: {gcp}</Box>
          <Box>GLN: {gln}</Box>
        </Stack>
      </Stack>

      <Box color={'text.400'} fontSize={'sm'}>
        Членство действительно до: {endDate}
      </Box>
    </Stack>
  )
}
