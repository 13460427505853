import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { Link, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'

import { printInvoice, printContract, printInvoiceCertificate } from '../../utils'

import { numberFormat, toNumber } from '~/utils/number'
import { useDateFormat } from '~/utils/date'
import { Table, TableBody, TableCol, TableHead, TableHeader, TableRow } from '~/components/Table'
import { MoreButton } from '~/components/Chakra/Menu'
import { useAuth } from '~/contexts/AuthProvider'

function getMembershipPeriod ({ admissionFee, membershipFee, period }) {
  if (!period) return null

  if (admissionFee && membershipFee) {
    return `Вступительный взнос и членский взнос ${period}`
  }
  if (admissionFee) {
    return `Вступительный взнос ${period}`
  }
  if (membershipFee) {
    return `Членский взнос ${period}`
  }
  return null
}

function InvoiceRowColumns (props) {
  const { contractNumber, contractId, isMain, invoice, requireSing } = props

  const { user } = useAuth()

  const tin = path(['company', 'tin'], user)

  const { dateFormat } = useDateFormat()

  const invoiceId = prop('id', invoice)
  const invoiceNumber = prop('invoiceNumber', invoice)
  const invoiceDate = dateFormat(prop('invoiceDate', invoice))
  const admissionFee = toNumber(prop('admissionFee', invoice))
  const membershipFee = toNumber(prop('membershipFee', invoice))
  const amount = numberFormat(admissionFee + membershipFee)
  const beginDate = dateFormat(prop('beginDate', invoice))
  const endDate = dateFormat(prop('endDate', invoice))
  const period = (beginDate && endDate) ? `за период с ${beginDate} по ${endDate}` : null
  const membershipPeriod = getMembershipPeriod({
    admissionFee,
    membershipFee,
    period
  })
  const activeDate = dateFormat(prop('activeDate', invoice))

  return (
    <Fragment>
      <TableCol span={4}>{invoiceNumber}</TableCol>
      <TableCol span={4}>{invoiceDate}</TableCol>
      <TableCol span={4}>{amount}</TableCol>
      <TableCol span={7}>{membershipPeriod}</TableCol>
      <TableCol span={4}>{activeDate}</TableCol>
      <TableCol span={4}>
        {isMain && requireSing
          ? (
            <Link
              href={`https://member.478.uz/admissin-contract?contractNumber=${contractNumber}&tin=${tin}`}
              target={'_blank'}
              color={'secondary.500'}
              rel={'noreferrer'}
            >
              Подписать
            </Link>
          )
          : null}
      </TableCol>
      <TableCol span={1}>
        <Menu>
          <MenuButton as={MoreButton} />
          <Portal>
            <MenuList>
              {isMain && (
                <MenuItem onClick={printContract.bind(null, contractId)}>
                  Распечатать договор
                </MenuItem>
              )}
              <MenuItem onClick={printInvoice.bind(null, invoiceId)}>
                Распечатать счёт
              </MenuItem>
              <MenuItem onClick={printInvoiceCertificate.bind(null, invoiceId)}>
                Распечатать свидетельство
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </TableCol>
    </Fragment>
  )
}

function FinanceList (props) {
  const { list } = props

  const { results, isLoading } = list

  const { dateFormat } = useDateFormat()

  return (
    <Fragment>
      <TableHeader title={'Финансы'} />

      <Table
        list={results}
        isLoading={isLoading}>
        <TableHead>
          <TableRow>
            <TableCol span={4}>Договор</TableCol>
            <TableCol span={4}>Дата договора</TableCol>
            <TableCol span={4}>Номер счета</TableCol>
            <TableCol span={4}>Дата счета</TableCol>
            <TableCol span={4}>Сумма счета</TableCol>
            <TableCol span={7}>Период членства</TableCol>
            <TableCol span={4}>Дата активации</TableCol>
            <TableCol span={4}>Ссылка на договор</TableCol>
            <TableCol span={1} />
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(item => {
            const id = prop('id', item)
            const contractNumber = prop('contractNumber', item)
            const contractDate = dateFormat(prop('contractDate', item))
            const invoices = prop('invoices', item) || []
            const requireSing = prop('requireSing', item)
            const [firstInvoice, ...restInvoices] = invoices

            return (
              <Fragment key={id}>
                <TableRow isBody={true}>
                  <TableCol span={4}>{contractNumber}</TableCol>
                  <TableCol span={4}>{contractDate}</TableCol>
                  <InvoiceRowColumns
                    contractNumber={contractNumber}
                    contractId={id}
                    isMain={true}
                    invoice={firstInvoice}
                    requireSing={requireSing}
                  />
                </TableRow>
                {restInvoices.map(invoice => {
                  const invoiceId = prop('id', invoice)

                  return (
                    <TableRow key={invoiceId} isBody={true}>
                      <TableCol span={4} />
                      <TableCol span={4} />
                      <InvoiceRowColumns invoice={invoice} />
                    </TableRow>
                  )
                })}
              </Fragment>
            )
          })}
        </TableBody>
      </Table>
    </Fragment>
  )
}

FinanceList.propTypes = {
  list: PropTypes.object.isRequired
}

InvoiceRowColumns.propTypes = {
  contractNumber: PropTypes.string,
  contractId: PropTypes.number,
  isMain: PropTypes.bool,
  requireSing: PropTypes.bool,
  invoice: PropTypes.object
}

export default FinanceList
