import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

function TableBody ({ children }) {
  const mapRows = (child, key) => cloneElement(child, {
    key,
    isBody: true
  })

  return (
    <Box color={'text.300'} fontSize={'sm'}>
      {Children.map(children, mapRows)}
    </Box>
  )
}

TableBody.propTypes = {
  children: PropTypes.node.isRequired
}

export default TableBody
