import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { ChevronLeft, ChevronRight } from 'react-iconly'
import { Box, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack } from '@chakra-ui/react'

import { useAddParamsURL, useGetSearchParam } from '~/hooks/urls'
import { toNumber } from '~/utils/number'
import { Row } from '~/components/Grid'

const CustomIconButton = forwardRef(function ForwardedIconButton (props, ref) {
  return (
    <IconButton
      ref={ref}
      colorScheme={'gray'}
      size={'sm'}
      variant={'ghost'}
      {...props}
    />
  )
})

const pageSizeList = [10, 25, 50, 100]

function TablePagination (props) {
  const { listCount } = props

  if (!listCount) {
    return null
  }

  const addParamsURL = useAddParamsURL()

  const getSearchParam = useGetSearchParam()

  const pageSize = getSearchParam('pageSize', '10')
  const page = getSearchParam('page', 1)
  const currentPage = toNumber(page)
  const pagesCount = Math.ceil(listCount / pageSize)

  const rangeStart = (pageSize * (currentPage - 1)) + 1
  const rangeEnd = listCount < (pageSize * currentPage) ? listCount : pageSize * currentPage

  function goToPage (page) {
    const select = getSearchParam('select')
    const params = select ? { page, select: null } : { page }
    addParamsURL(params)
  }

  function toPrevPage () {
    goToPage(currentPage - 1)
  }

  function toNextPage () {
    goToPage(currentPage + 1)
  }

  function onChangePageSize (pageSize) {
    addParamsURL({ page: 1, pageSize })
  }

  const elementsCountLabel = `${rangeStart} - ${rangeEnd} из ${listCount}`
  const pagesCountLabel = `${currentPage} из ${pagesCount}`

  return (
    <Row
      align={'center'}
      borderTop={'1px solid'}
      borderColor={'custom.border'}
      fontSize={'sm'}
      justify={'space-between'}
      minH={'60px'}
      pl={5}
      py={2}>
      <Stack align={'center'} direction={'row'} spacing={4}>
        <Box>
          {elementsCountLabel}
        </Box>
        <Flex align={'center'}>
          <Box>Кол-во на странице:</Box>
          <Menu isLazy={true} lazyBehavior={'keepMounted'}>
            <MenuButton
              as={CustomIconButton}
              aria-label={'Items per page'}
              icon={<Box>{pageSize}</Box>}
            />
            <MenuList minW={20}>
              {pageSizeList.map(item => {
                const isActive = String(item) === pageSize

                return (
                  <MenuItem
                    key={item}
                    color={isActive ? 'secondary.500' : 'inherit'}
                    fontWeight={isActive ? 'semibold' : 'inherit'}
                    pointerEvents={isActive ? 'none' : 'unset'}
                    onClick={onChangePageSize.bind(null, item)}>
                    {item}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Menu>
        </Flex>
      </Stack>

      {pagesCount > 1 && (
        <Stack align={'center'} direction={'row'}>
          <CustomIconButton
            aria-label={'To prev page'}
            icon={<Icon as={ChevronLeft} />}
            isDisabled={equals(1, currentPage)}
            onClick={toPrevPage}
          />

          <Box>{pagesCountLabel}</Box>

          <CustomIconButton
            aria-label={'To next page'}
            icon={<Icon as={ChevronRight} />}
            isDisabled={equals(pagesCount, currentPage)}
            onClick={toNextPage}
          />
        </Stack>
      )}
    </Row>
  )
}

TablePagination.propTypes = {
  listCount: PropTypes.number.isRequired
}

export default TablePagination
