import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Edit, Download } from 'react-iconly'
import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack
} from '@chakra-ui/react'

import { getBase64StringSize } from '~/utils/base64'
import { humanizeFileSize } from '~/utils/file'
import { MoreButton } from '~/components/Chakra/Menu'
import Barcode from '~/components/Barcode'

/* eslint-disable */
function ProductBarcode (props) {
  const { barcode } = props

  const [barcodeSrc, setBarcodeSrc] = useState('')

  const barcodeSize = barcodeSrc ? getBase64StringSize(barcodeSrc) : null

  function onLoadBarcode (event) {
    const imgSrc = event.target.currentSrc
    setBarcodeSrc(imgSrc)
  }

  function onDownloadBarcode () {
    if (barcodeSrc) {
      const a = document.createElement('a')
      a.href = barcodeSrc
      a.download = `barcode_${barcode}.png`
      a.click()
    }
  }

  return (
    <Flex
      bgColor={'custom.lightGray'}
      borderRadius={'sm'} p={'10px'}>
      <Stack direction={'row'} flexGrow={1} spacing={4}>
        <Box
          bgColor={'white'}
          border={'1px solid'}
          borderColor={'#dddfe5'}
          borderRadius={'sm'}
          p={1}
          h={20}
          w={24}
          sx={{
            '& svg': { h: 'full', w: 'full' }
          }}>
          <Barcode
            render={'img'}
            value={barcode}
            background={'transparent'}
            // displayValue={false}
            onLoad={onLoadBarcode}
          />
        </Box>

        <Stack spacing={1}>
          <Box color={'primary.900'} fontSize={'sm'}>
            Штрих код {barcode}
          </Box>
          {barcodeSize && (
            <Box color={'text.100'} fontSize={'xs'}>
              {humanizeFileSize(barcodeSize)}
            </Box>
          )}
        </Stack>
      </Stack>

      <Icon
        as={Download}
        boxSize={6}
        cursor={'pointer'}
        _hover={{ color: 'custom.blue' }}
        onClick={onDownloadBarcode}
      />
      {false && (
        <Box>
          <Menu>
            <MenuButton as={MoreButton} />
            <MenuList>
              <MenuItem icon={<Icon as={Edit} boxSize={'18px'} />}>
                Редактировать
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={<Icon as={Download} boxSize={'18px'} />} onClick={onDownloadBarcode}>
                Загрузить
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  )
}

ProductBarcode.propTypes = {
  barcode: PropTypes.string.isRequired
}

export default ProductBarcode
