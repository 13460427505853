import { prop } from 'ramda'

import useGetApi from '../api/useGetApi'
import useDeepCompareEffect from '../app/useDeepCompareEffect'

export function toDetail (state) {
  const data = prop('data', state) || {}
  const isLoading = state.isLoading
  const getDetail = state.getDetail

  return { data, isLoading, getDetail }
}

export default function useDetail (api, { params, autoSend = true } = {}) {
  const { get, ...state } = useGetApi(api, { params })
  const getDetail = get

  useDeepCompareEffect(() => {
    if (autoSend) {
      getDetail(params)
    }
  }, [params, autoSend])

  return toDetail({ ...state, getDetail })
}
