import React from 'react'
import { Center, CircularProgress } from '@chakra-ui/react'

export default function AuthSkeleton () {
  return (
    <Center h={'100vh'} w={'100vw'}>
      <Center
        bgColor={'whiteAlpha.600'}
        boxShadow={'sm'}
        rounded={'lg'}
        p={6}>
        <CircularProgress
          color={'primary.500'}
          trackColor={'gray.200'}
          isIndeterminate={true}
          size={'60px'}
          thickness={7}
          value={100}
        />
      </Center>
    </Center>
  )
}
