export default {
  primary: {
    50: '#f5f5f8',
    100: '#eaebf1',
    200: '#cccedd',
    300: '#adb0c8',
    400: '#6f749f',
    500: '#313976',
    600: '#2c336a',
    700: '#252b59',
    800: '#1d2247',
    900: '#181c3a'
  },
  secondary: {
    50: '#fef7f5',
    100: '#fef0ec',
    200: '#fbd8cf',
    300: '#f9c1b1',
    400: '#f59377',
    500: '#f0643d',
    600: '#d85a37',
    700: '#b44b2e',
    800: '#903c25',
    900: '#76311e'
  },
  bg: {
    100: '#fbfbfc',
    200: '#f6f6f8'
  },
  custom: {
    border: '#e1e5ea',
    lightGray: '#f2f2f5',
    blue: '#117be5',
    red: '#f23f6c',
    green: '#7cbf4b'
  },
  text: {
    100: '#8f97a8',
    200: '#77778c',
    300: '#43434a',
    400: '#3e3e46',
    500: '#22222a'
  }
}
