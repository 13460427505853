import { pipe } from 'ramda'
import { useLocation } from 'react-router-dom'

import { searchToQuery } from '~/utils/url'

export default function useGetSearchParams () {
  const { search } = useLocation()

  return pipe(decodeURIComponent, searchToQuery)(search)
}
