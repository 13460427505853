import React from 'react'
import { path, propOr } from 'ramda'

import { useUploadApi } from '~/hooks/api'
import * as API from '~/constants/api'
import { getDataFromSuccess } from '~/hooks/api/utils'
import { getHTMLFromErrorData } from '~/constants/excel'
import { useToast } from '~/hooks/app'

// Only for single files
export const useUploadExcel = props => {
  const {
    url
  } = props

  const toast = useToast()

  const onSuccess = propOr(() => {}, ['onSuccess'], props)

  const toastTitle = 'Файл Excel'

  const {
    upload: uuidUpload,
    isLoading: uuidIsLoading
  } = useUploadApi({
    url,
    onSuccess: (res) => {
      toast({
        title: toastTitle,
        description: 'Успешно загружено',
        status: 'success'
      })

      onSuccess(res)
    },
    onFailed: (err) => {
      toast({
        title: toastTitle,
        description: (
          <div dangerouslySetInnerHTML={{ __html: getHTMLFromErrorData(err) }} />
        ),
        status: 'error'
      })
    }
  })

  const {
    upload: fileUpload,
    isLoading: fileIsLoading
  } = useUploadApi({
    url: API.FILE_UPLOAD,
    onSuccess: (res) => {
      const data = getDataFromSuccess(res)
      const excelFile = data?.id

      const formData = new FormData()
      formData.append('excel', excelFile)

      uuidUpload(formData, () => {})
    },
    onFailed: (err) => {
      const status = path(['response', 'status'], err)

      const errDescription = () => {
        switch (status) {
          case 500: return 'Ошибка сервера'
          case 413: return 'Размер файла слишком большой'
          default: return JSON.stringify(err)
        }
      }

      toast({
        title: toastTitle,
        description: errDescription,
        status: 'error'
      })
    }
  })

  const onChangeSubmit = (event) => {
    const excelFile = event?.target?.files[0]

    const formData = new FormData()
    formData.append('file', excelFile)

    fileUpload(formData, () => {})

    toast({
      title: toastTitle,
      description: 'Дождитесь завершения загрузки',
      status: 'info'
    })

    setImmediate(() => {
      event.target.value = null
    })
  }

  const isLoading = fileIsLoading | uuidIsLoading

  return {
    isLoading,
    onChangeSubmit
  }
}
