import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { propOr } from 'ramda'
import { Center, Spinner } from '@chakra-ui/react'

import { useDetail } from '~/hooks/crud'

const MenuContext = createContext({
  menu: {},
  isLoading: false
})

export function MenuProvider ({ children }) {
  const { data, isLoading } = useDetail('https://api.gs1.uz/menu/')

  const menu = useMemo(() => propOr({}, 'data', data), [data])

  return (
    <MenuContext.Provider value={{ menu, isLoading }}>
      <Center
        bgColor={'white'}
        pos={'fixed'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        opacity={isLoading ? 1 : 0}
        transitionProperty={'all'}
        transitionDuration={'ultra-slow'}
        transitionTimingFunction={'ease-out'}
        visibility={isLoading ? 'visible' : 'hidden'}
        zIndex={'modal'}>
        <Spinner
          thickness={'4px'}
          emptyColor={'gray.200'}
          color={'secondary.500'}
          size={'xl'}
        />
      </Center>

      {children}
    </MenuContext.Provider>
  )
}

export function useAppMenu () {
  return useContext(MenuContext)
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired
}
