import React, { forwardRef } from 'react'
import { Box } from '@chakra-ui/react'

export default forwardRef(function DashedButtonWrap (props, ref) {
  return (
    <Box
      bgColor={'bg.100'}
      border={'2px dashed'}
      borderColor={'#b7bcc7'}
      borderRadius={'sm'}
      cursor={'pointer'}
      ref={ref}
      transition={'border 200ms ease-in-out'}
      _hover={{ borderColor: 'text.100' }}
      {...props}
    />
  )
})
