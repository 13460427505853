export const defaultProps = {
  colorScheme: 'primary',
  errorBorderColor: 'custom.red',
  focusBorderColor: 'primary.500',
  variant: 'outline'
}

export const sizes = {
  md: {
    field: {
      borderRadius: 'sm',
      fontWeight: 'inherit',
      h: '52px',
      px: 3
    },
    addon: {
      borderRadius: 'sm',
      h: '52px'
    }
  }
}
