import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chakra-ui/react'

function LabelWrap (props) {
  const { children, label } = props

  return (
    <Flex>
      <Flex
        align={'center'}
        alignSelf={'baseline'}
        color={'primary.900'}
        flexBasis={32}
        mr={12}
        minH={'52px'}
        minW={32}
        w={32}>
        {label}
      </Flex>

      <Box flexGrow={1}>{children}</Box>
    </Flex>
  )
}

LabelWrap.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
}

export default LabelWrap
