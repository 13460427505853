const API_PROTOCOL = 'https'
const API_DOMAIN_URL = 'api.478.uz'
const API_VERSION = 'api/v1'
const API_VERSION_ACTIVATE = 'api/activate/v1'
export const API_BASE_URL = process.env.NODE_ENV !== 'production' ? '/stage' : `${API_PROTOCOL}://${API_DOMAIN_URL}`
// http://api.478.uz/api/activate/v1/contracts/

const AUTH = `${API_VERSION}/auth`
export const SEND_SMS = `/${AUTH}/sms/`
export const SEND_CODE = `/${AUTH}/sms/verify/`
export const LOGOUT = `${AUTH}/logout/`
export const ME = `${API_VERSION}/me/`

const FILE = `${API_VERSION}/upload`
export const FILE_UPLOAD = `/${FILE}/`

const GEO = `${API_VERSION}/geo`
export const COUNTRY_LIST = `/${GEO}/country/`

const PRODUCT = `${API_VERSION}/products/user`
const PRODUCT_OPTIONS = `/${PRODUCT}/%s/`
export const PRODUCT_LIST = `/${PRODUCT}/`
export const PRODUCT_CREATE = `/${PRODUCT}/`
export const PRODUCT_DETAIL = PRODUCT_OPTIONS
export const PRODUCT_EDIT = PRODUCT_OPTIONS
export const PRODUCT_DELETE = PRODUCT_OPTIONS
export const PRODUCT_PRINT = `/${PRODUCT}/pdf/`

const PRODUCT_TYPE = `${API_VERSION}/products/product-types`
export const PRODUCT_TYPE_LIST = `/${PRODUCT_TYPE}/`

const CONTRACT_ACTIVATE = `${API_VERSION_ACTIVATE}/contracts`
export const CONTRACT_LIST = `/${CONTRACT_ACTIVATE}/`

const CONTRACT = `${API_VERSION}/contracts`
export const CONTRACT_PRINT = `/${CONTRACT}/%s/pdf/`

const INVOICE = `${API_VERSION}/invoices`
export const INVOICE_PRINT = `/${INVOICE}/%s/pdf/`
export const INVOICE_CERTIFICATE_PRINT = `/${INVOICE}/%s/certificate_pdf/`

export const EXCEL_DOWNLOAD = `/${API_VERSION}/products/excel_uploading`

export const EXCEL_PRODUCTS_DOWNLOAD_BY_GCP = `/${API_VERSION}/products/user/export/?search=%s`
