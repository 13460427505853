import { sprintf } from 'sprintf-js'

import * as API from '~/constants/api'
import * as ROUTES from '~/constants/routes'
import { useCreate, useDetail, useList, useRemove, useUpdate } from '~/hooks/crud'
import { useGetSearchParams } from '~/hooks/urls'
import { useRedirect } from '~/hooks/app'

export function useRedirectDetail () {
  return useRedirect(ROUTES.PRODUCT_DETAIL_URL)
}

export function useRedirectEdit () {
  return useRedirect(ROUTES.PRODUCT_EDIT_URL)
}

export function useProductList () {
  const params = useGetSearchParams()
  const { product, ...restParams } = params
  return useList(API.PRODUCT_LIST, { params: restParams })
}

export function useProductDetail (id, options) {
  const api = sprintf(API.PRODUCT_DETAIL, id)
  return useDetail(api, options)
}

export function useProductDelete (id) {
  const api = sprintf(API.PRODUCT_DELETE, id)
  return useRemove(api)
}

export function useProductCreate () {
  return useCreate(API.PRODUCT_CREATE)
}

export function useProductEdit (id) {
  const api = sprintf(API.PRODUCT_EDIT, id)
  return useUpdate(api)
}
