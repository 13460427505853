import React from 'react'
import { Stack } from '@chakra-ui/react'

import LoginInput from './LoginInput'

export default function LoginCodeField () {
  return (
    <Stack>
      <LoginInput
        name={'smsCode'}
        label={'Введите код'}
        maxLength={6}
        rules={{
          required: 'Это поле обязательно'
        }}
      />
    </Stack>
  )
}
