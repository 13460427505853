import React, { createContext, useState, useContext, useCallback } from 'react'

import Login from './Login'

const StepperContext = createContext({})

export function useStepper () {
  return useContext(StepperContext)
}

export function LoginStepperProvider () {
  const [step, setStep] = useState(0)

  const toPrevStep = useCallback(() => {
    setStep(prevStep => prevStep - 1)
  }, [])

  const toNextStep = useCallback(() => {
    setStep(prevStep => prevStep + 1)
  }, [])

  const stepperProps = {
    step,
    toPrevStep,
    toNextStep
  }

  return (
    <StepperContext.Provider value={stepperProps}>
      <Login />
    </StepperContext.Provider>
  )
}
