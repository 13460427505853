import { useLocation } from 'react-router-dom'

import { searchToQuery } from '~/utils/url'

export default function useGetSearchParam () {
  const { search } = useLocation()

  const searchQuery = searchToQuery(search)

  return function (key, fallbackValue) {
    return searchQuery[key] || fallbackValue
  }
}
