import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Stack, Text } from '@chakra-ui/react'

function DetailTitle (props) {
  const { children, description } = props

  return (
    <Stack mb={10}>
      <Heading fontSize={'2xl'} fontWeight={'medium'}>
        {children}
      </Heading>
      {description && (
        <Text color={'text.200'} fontSize={'lg'}>
          {description}
        </Text>
      )}
    </Stack>
  )
}

DetailTitle.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string
}

export default DetailTitle
