import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  defaultTo,
  is,
  join,
  map,
  path,
  pathOr,
  pipe,
  prop
} from 'ramda'
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { sprintf } from 'sprintf-js'

import { printSelectedProducts } from '../../utils'
import ProductStatus from '../ProductStatus'

import * as CONST from '~/constants/constants'
import { numberFormat } from '~/utils/number'
import { useAddParamsURL, useGetSearchParam, useGetSearchParams } from '~/hooks/urls'
import {
  TableHeader,
  TableSearch,
  TablePagination,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCol
} from '~/components/Table'
import {
  CreateButton,
  ExcelDownloadButton,
  PrintButton
} from '~/components/Buttons'
import Barcode from '~/components/Barcode'
import * as API from '~/constants/api'
import { useUploadExcel } from '~/components/Excel/useUploadExcel'
import External from '~/icons/External'
import { useAuth } from '~/contexts/AuthProvider'

function ProductList (props) {
  const { list, goDetail } = props

  const { results, isLoading, count, getList } = list

  const addParamsURL = useAddParamsURL()

  const getSearchParam = useGetSearchParam()

  const params = useGetSearchParams()

  const select = getSearchParam('select')
  const page = getSearchParam('page', 1)

  const inputRef = useRef()

  const { user } = useAuth()

  const gcps = pathOr([], ['company', 'gcps'], user)

  const {
    isOpen: isExcelOpen,
    onOpen: onExcelOpen,
    onClose: onExcelClose
  } = useDisclosure()

  function onPrint () {
    printSelectedProducts(select)
  }

  const {
    onChangeSubmit: excelOnChangeSubmit,
    isLoading: excelIsLoading
  } = useUploadExcel({
    url: API.EXCEL_DOWNLOAD,
    onSuccess: () => {
      const { product, ...restParams } = params

      if (+page === 1) {
        getList(restParams)
        if (select) {
          addParamsURL({ select: null })
        }
      } else {
        addParamsURL({ page: 1, select: null })
      }
    }
  })

  function onExcelUploadButtonClick () {
    if (inputRef && inputRef?.current) {
      inputRef.current?.click()
    }
  }

  return (
    <Fragment>
      <TableHeader title={'Продукция'}>
        <Stack direction={'row'}>
          <ExcelDownloadButton
            onClick={onExcelOpen}
            title={'Файлы Excel'}
          />
          <PrintButton
            isDisabled={!select}
            onClick={onPrint}
          />
        </Stack>
        <CreateButton />
      </TableHeader>

      <TableSearch placeholder={'Поиск продукта по наименованию или GTIN...'} />

      <Table
        list={results}
        isLoading={isLoading}
        isSelectable={true}>

        <TableHead>
          <TableRow>
            <TableCol span={3}>GTIN-13</TableCol>
            <TableCol span={4}>Торговая марка</TableCol>
            <TableCol span={4}>Вторичная торговая марка</TableCol>
            <TableCol span={4}>Описание</TableCol>
            <TableCol span={4}>Упаковка и кол-во</TableCol>
            <TableCol span={4}>Целевые рынки</TableCol>
            <TableCol span={4}>GPC (Brick code)</TableCol>
            <TableCol span={3}>Статус</TableCol>
            <TableCol span={2} textAlign={'right'}>Штрих код</TableCol>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(item => {
            const id = prop('id', item)
            const myEancode = prop('myEancode', item)
            const tradeMark = prop('tradeMark', item)
            const name = prop('name', item)
            const description = prop('description', item)
            const packageName = prop('package', item)
            const quantity = prop('quantity', item)
            const measurement = prop('measurement', item)
            const measurementName = CONST.MEASUREMENT.object[measurement]
            const quantityMeasurement = numberFormat(quantity, measurementName)
            const targetMarket = prop('targetMarket', item)
            const targetMarkets = is(Array, targetMarket)
              ? pipe(
                map(prop('shortName')),
                join(', '),
                defaultTo('-')
              )(targetMarket)
              : '-'
            const brickCode = path(['productType', 'code'], item)
            const status = prop('status', item)

            return (
              <TableRow key={id} id={id} onClick={goDetail.bind(null, id)}>
                <TableCol span={3}>
                  <Box wordBreak={'break-all'}>{myEancode}</Box>
                </TableCol>
                <TableCol span={4}>{tradeMark}</TableCol>
                <TableCol span={4}>{name}</TableCol>
                <TableCol span={4}>{description}</TableCol>
                <TableCol span={4}>
                  <Box wordBreak={'break-all'}>{packageName}</Box>
                  <Box>{quantityMeasurement}</Box>
                </TableCol>
                <TableCol span={4}>{targetMarkets}</TableCol>
                <TableCol span={4}>{brickCode}</TableCol>
                <TableCol span={3}>
                  <ProductStatus status={status} />
                </TableCol>
                <TableCol span={2}>
                  {myEancode && (
                    <Box
                      bgColor={'white'}
                      borderColor={'custom.border'}
                      borderWidth={1}
                      borderRadius={'base'}
                      ml={'auto'}
                      p={1}
                      w={'fit-content'}>
                      <Barcode
                        value={myEancode}
                        background={'transparent'}
                        displayValue={false}
                        flat={true}
                        margin={0}
                        height={10}
                        width={0.15}
                      />
                    </Box>
                  )}
                </TableCol>
              </TableRow>
            )
          })}
        </TableBody>
        <TablePagination listCount={count} />
      </Table>
      <Modal
        closeOnOverlayClick={true}
        isOpen={isExcelOpen}
        onClose={onExcelClose}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent
          pb={'15px'}
        >
          <ModalCloseButton />
          <ModalHeader>Общее</ModalHeader>
          <ModalBody>
            <List spacing={'15px'}>
              <ListItem>
                <Link
                  href={`${API.API_BASE_URL}${API.EXCEL_DOWNLOAD}`}
                  isExternal={true}
                >
                  Скачать шаблон Excel
                  <External ml={'5px'} mb={'4px'} />
                </Link>
              </ListItem>
              <ListItem>
                <div>
                  <Button
                    color={'text.400'}
                    bg={'transparent'}
                    fontWeight={'normal'}
                    p={0}
                    h={'auto'}
                    onClick={onExcelUploadButtonClick}
                    disabled={excelIsLoading}
                    _hover={{ textDecoration: 'underline' }}
                    _active={{ textDecoration: 'underline' }}
                    _focus={{ textDecoration: 'underline' }}
                    _disabled={{ opacity: 0.5 }}
                  >
                    Загрузить продукцию
                    <External ml={'5px'} mb={'4px'} />
                  </Button>
                  <input
                    hidden={true}
                    ref={inputRef}
                    onChange={excelOnChangeSubmit}
                    type={'file'}
                  />
                </div>
              </ListItem>
            </List>
          </ModalBody>
          <ModalHeader>GCP</ModalHeader>
          <ModalBody
            maxH={'345px'}
          >
            <List spacing={'15px'}>
              {gcps?.map(({ gcp, id }) => {
                if (gcp) {
                  const link = API.API_BASE_URL + sprintf(API.EXCEL_PRODUCTS_DOWNLOAD_BY_GCP, gcp)

                  return (
                    <ListItem
                      key={id}
                    >
                      <Link
                        href={link}
                        isExternal={true}
                      >
                        {gcp}
                        <External ml={'5px'} mb={'4px'} />
                      </Link>
                    </ListItem>
                  )
                } else {
                  return null
                }
              })}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}

ProductList.propTypes = {
  list: PropTypes.object.isRequired,
  goDetail: PropTypes.func.isRequired
}

export default ProductList
