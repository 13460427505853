import React from 'react'
import PropTypes from 'prop-types'
import { Circle, Flex } from '@chakra-ui/react'

import Plus from '~/icons/Plus'
import { DashedButtonWrap } from '~/components/Wraps'

function FieldArrayButton (props) {
  const { children, onClick } = props

  return (
    <Flex
      as={DashedButtonWrap}
      align={'center'}
      justify={'center'}
      h={12}
      onClick={onClick}>
      <Circle bgColor={'custom.blue'} mr={2} size={5}>
        <Plus boxSize={'14px'} color={'white'} />
      </Circle>

      {children}
    </Flex>
  )
}

FieldArrayButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
}

export default FieldArrayButton
