/* eslint-disable */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { head, isEmpty, map, not, pipe, prop, propOr } from 'ramda'
import { useDropzone } from 'react-dropzone'
import { useController, useFormContext } from 'react-hook-form'
import { Box, Flex, Progress, Stack, Text, useMergeRefs } from '@chakra-ui/react'

import useUploadFile from './useUploadFile'
import FileImageItem from './FileImageItem'

import { humanizeFileSize } from '~/utils/file'
import { useToast } from '~/hooks/app'
import CloudUpload from '~/icons/CloudUpload'
import { useFieldError } from '~/components/HookForm'
import { DashedButtonWrap } from '~/components/Wraps'

function getProgressPercent (progress) {
  if (!progress) return ''
  return `${progress} %`
}

function FileDropzone (props) {
  const { name, isMulti, maxSize, aclType = 'private' } = props

  const toast = useToast()

  const inputRef = useRef()

  const { control } = useFormContext()

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  })

  const { state, onDropAccepted, onClear } = useUploadFile({ field, inputRef, isMulti, aclType })

  const errorMessage = useFieldError(fieldState.error)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    inputRef: dropInputRef
  } = useDropzone({
    accept: 'image/*',
    maxFiles: isMulti ? undefined : 1,
    maxSize,
    onDropAccepted,
    onDropRejected: fileRejections => {
      const file = head(fileRejections)
      const fileErrors = pipe(
        prop('errors'),
        map(prop('message'))
      )(file)

      toast({
        title: 'Error',
        description: fileErrors.map((errorMessage, index) => (
          <Box key={index}>{errorMessage}</Box>
        )),
        status: 'error'
      })
    }
  })

  const mergedInputRef = useMergeRefs(inputRef, dropInputRef)

  function getBorderColor () {
    if (isDragAccept) {
      return 'custom.blue'
    }
    if (isDragReject) {
      return 'custom.red'
    }
    if (isDragActive) {
      return 'custom.blue'
    }
    return '#b7bcc7'
  }

  const file = prop('value', field)
  const files = propOr([], 'value', field)

  const maxFileSize = humanizeFileSize(maxSize)

  return (
    <Stack spacing={4}>
      <Flex
        as={DashedButtonWrap}
        align={'center'}
        borderColor={getBorderColor()}
        direction={'column'}
        justify={'center'}
        flex={1}
        minH={36}
        px={12}
        py={6}
        {...getRootProps()}>
        <input {...getInputProps()} ref={mergedInputRef} multiple={false} />

        {state.isLoading
          ? (
            <Stack align={'center'} w={'full'}>
              <Progress
                colorScheme={'secondary'}
                isAnimated={true}
                hasStripe={true}
                size={'lg'}
                value={state.progress}
                w={'full'}
              />
              <Box color={'primary.900'} fontSize={'sm'} fontWeight={'medium'}>
                {getProgressPercent(state.progress)}
              </Box>
            </Stack>
          )
          : (
            <Stack align={'center'} pointerEvents={'none'}>
              <CloudUpload boxSize={'34px'} color={'text.100'} />

              <Stack fontSize={'sm'} spacing={1} textAlign={'center'}>
                <Text color={'primary.900'}>
                  Перетащите файл сюда, или <Box as={'span'} color={'custom.blue'}>нажмите для загрузки</Box>
                </Text>
                {maxSize && (
                  <Text color={'text.100'}>Макс. размер файла {maxFileSize}</Text>
                )}
              </Stack>
            </Stack>
          )}
      </Flex>

      {fieldState.invalid && (
        <Box color={'custom.red'}>
          {errorMessage}
        </Box>
      )}

      {(field.value && !isMulti) && (
        <FileImageItem file={file} onClear={onClear} />
      )}

      {isMulti && not(isEmpty(files)) && (
        <Stack>
          {files.map(item => (
            <FileImageItem key={item.id} file={item} onClear={onClear} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

FileDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  maxSize: PropTypes.number
}

export default FileDropzone
