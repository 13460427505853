import React from 'react'
import { Heading } from '@chakra-ui/react'

export default function LoginHeading (props) {
  return (
    <Heading
      color={'primary.500'}
      fontSize={'3xl'}
      fontWeight={'medium'}
      {...props}
    />
  )
}
