/**
 * @param {Object} menuItem
 * @param {String} menuItem.navKey
 * @param {String} menuItem.type
 * @return {String}
 * */
export const getMenuItemUrl = (menuItem) => {
  const EXTERNAL_URL = 'https://gs1.uz'

  if (!menuItem) return '/'

  const urlMap = {
    dynamic: menuItem.navKey,
    link: menuItem.navKey,
    static_front_page: menuItem.navKey
  }
  return EXTERNAL_URL + urlMap[menuItem.type]
}
