import { head, path, prop, propOr } from 'ramda'

import useUploadReducer from './useUploadReducer'

import * as API from '~/constants/api'
import request from '~/utils/request'

export default function useUploadFile ({ field, inputRef, isMulti, aclType = 'private' }) {
  const [state, dispatch] = useUploadReducer()

  function onUploadFile (file) {
    const formData = new FormData()

    function onUploadProgress (progressEvent) {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      dispatch({ progress: percentCompleted })
    }

    if (!isMulti) {
      field.onChange(null)
    }

    formData.append('file', file)
    formData.append('acl_type', aclType)
    dispatch({ isLoading: true })

    request.upload(API.FILE_UPLOAD, formData, onUploadProgress)
      .then(response => {
        const uploadedFile = response.data
        // eventTarget.value = ''
        dispatch({
          isLoading: false,
          error: null,
          progress: 0
        })

        if (isMulti) {
          const prevValue = propOr([], 'value', field)
          field.onChange([...prevValue, uploadedFile])
        } else {
          field.onChange(uploadedFile)
        }

        return response
      })
      .catch(serverError => {
        // eventTarget.value = ''
        const status = path(['response', 'status'], serverError)
        const errorData = path(['response', 'data'], serverError)

        dispatch({ isLoading: false, progress: 0 })

        switch (status) {
          case 500: return dispatch({
            error: 'Ошибка сервера'
          })
          case 413: return dispatch({
            error: 'Размер файла слишком большой'
          })
          default: return dispatch({
            error: errorData
          })
        }
      })
  }

  function onInputChange (event) {
    const eventTarget = prop('target', event)
    const file = path(['files', '0'], eventTarget)

    if (!event.target.value.length) {
      return event.preventDefault()
    }

    if (file) {
      onUploadFile(file)
    }
  }

  function onDropAccepted (files) {
    const file = head(files)
    onUploadFile(file)
  }

  function onClear (id) {
    const inputElement = inputRef.current
    inputElement.value = ''

    if (isMulti) {
      field.onChange(field.value?.filter(file => {
        return file.id !== id
      }))
    } else {
      field.onChange(null)
    }
  }

  return {
    state,
    onInputChange,
    onDropAccepted,
    onClear
  }
}
